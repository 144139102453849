import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { CustomizedTooltipHighlightedText } from '../../components/Tooltips/Tooltip';
import { ReactComponent as AddColumn } from '../../images/icons/add-column.svg';
import './ColumnSelectorButton.css';

const ColumnSelectorButton = ({
  columns,
  visibleColumns,
  handleColumnChange,
  className
}) => {
  const columnSelectorRef = useRef(null);

  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);

  const toggleColumnSelector = () => {
    setIsColumnSelectorOpen(prev => !prev);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (columnSelectorRef.current && !columnSelectorRef.current.contains(event.target)) {
        setIsColumnSelectorOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [columnSelectorRef, setIsColumnSelectorOpen]);

  return (
    !isColumnSelectorOpen ? (
      <div className='add-columns-button-container'>
        <CustomizedTooltipHighlightedText
          text={'Show additional columns'}
          placement={'right'}
          component={
            <div>
              <button
                className={`add-columns-button ${className}`}
                onClick={toggleColumnSelector}
              >
                <AddColumn />
              </button>
            </div>
          }
        />
      </div>
    ) : (
      <div className='add-columns-button-container'>
        <button
          className={`add-columns-button ${className}`}
          onClick={toggleColumnSelector}
        >
          <AddColumn />
        </button>
        <div
          className={cn('column-selector-modal', {
            'column-selector-modal--open': isColumnSelectorOpen
          })}
          ref={columnSelectorRef}
        >
          {columns
            .filter(column => !['checkbox', 'edit'].includes(column.type) && !column.required)
            .map(column => (
              <label key={column.key} className='column-selector-label'>
                <input
                  type='checkbox'
                  checked={visibleColumns.includes(column.key)}
                  onChange={() => handleColumnChange(column.key)}
                />
                {column.label}
              </label>
            ))}
        </div>
      </div>
    )
  );
};

export default ColumnSelectorButton;
