import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';
import { useHttp } from '../../../hooks/http.hook';
import { useCrypto } from '../../../hooks/crypto.hook';
import { AnalyticsGeneralContext } from '../../../context/AnalyticsGeneralContext';
import { AuthContext } from '../../../context/AuthContext';
import { dateRangeOptionsAnalytics, lastThirtyDaysChunk } from '../../../config/config';
import { UserAnalyticsFilterBar } from '../UserAnalyticsFilterBar/UserAnalyticsFilterBar';
import { SummaryMetrics } from './SummaryMetrics';
import { TrendGraph } from '../TrendGraph/TrendGraph';
import { ProneTable } from '../ProneTable/ProneTable';
import { Loader } from '../../Loader/Loader';
import './ExecutiveSummary.css';

export const ExecutiveSummary = () => {
  const {
    tenants: contextTenants,
    departments: contextDepartments,
    locations: contextLocations
  } = useContext(AnalyticsGeneralContext);

  const { token, showToastMessage } = useContext(AuthContext);
  const { request, loading } = useHttp();
  const { encryptData, decryptData } = useCrypto();
  const location = useLocation();

  const [tenants, setTenants] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [minMailingDate, setMinMailingDate] = useState(moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD'));
  const [maxMailingDate, setMaxMailingDate] = useState(moment().format('YYYY-MM-DD'));
  const [filteredMinMailingDate, setFilteredMinMailingDate] = useState(null);
  const [summaryData, setSummaryData] = useState({});
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [dateRangeOptionsList, setDateRangeOptionsList] = useState(dateRangeOptionsAnalytics);
  const [selectedDateRange, setSelectedDateRange] = useState(dateRangeOptionsAnalytics[0]);
  const [disabled, setDisabled] = useState(false);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    setTenants(contextTenants);
    setDepartments(contextDepartments);
    setLocations(contextLocations);
  }, [contextDepartments, contextLocations, contextTenants]);

  const clearGroup = () => {
    setSelectedGroup(null);
    setNoData(false);
    setDisabled(false);
  }

  const handleTenantChange = (data) => {
    clearGroup();
    setSelectedTenant(data);
  }

  const handleGroupChange = (data) => {
    clearGroup();
    setSelectedGroup(data);
  }

  const generateReport = async (receivers, summaryPageSelect, riskPageSelect, phishingPageSelect, educationPageSelect) => {
    try {
      if (!receivers.length) {
        showToastMessage('Error', 'Please select at least one receiver');
        return;
      }
      const receiversData = receivers.map(receiver => ({
        email: receiver.email,
        firstName: receiver.firstName,
        lastName: receiver.lastName || receiver.secondName
      }));

      setDisabled(true);
      const data = encryptData({
        department: selectedDepartment ? selectedDepartment.label : null,
        location: selectedLocation ? selectedLocation.label : null,
        groupId: selectedGroup ? selectedGroup.value : null,
        groupName: selectedGroup ? selectedGroup.label : null,
        tenantId: selectedTenant ? selectedTenant.id : null,
        startDate: minMailingDate,
        endDate: maxMailingDate,
        dateRange: selectedDateRange ? selectedDateRange.value : null,
        receiversData,
        summaryPageSelect,
        riskPageSelect,
        phishingPageSelect,
        educationPageSelect
      });

      const result = await request('/back_office/api/analytics/generate_report', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      });

      showToastMessage(result.error, result.message);
      if (result.message) return true;
      return false;
    } catch (error) {
      console.error('Error generating report:', error);
      return false;
    } finally {
      setDisabled(false);
    }
  }

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const dateRangeParam = params.get('date_range');
  const startDateParam = params.get('start_date');
  const endDateParam = params.get('end_date');
  const companyId = params.get('company_id');
  const tenantId = params.get('tenant_id');
  const departmentParam = params.get('department');
  const locationParam = params.get('location');
  const groupIdParam = params.get('group_id');
  const groupNameParam = params.get('group_name');

  const fetchSummaryData = useCallback(async () => {
    try {
      if (token && minMailingDate) {
        const data = encryptData({
          department: selectedDepartment ? selectedDepartment.label : null,
          location: selectedLocation ? selectedLocation.label : null,
          groupId: selectedGroup ? selectedGroup.value : null,
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate,
          maxMailingDate,
          dateRange: selectedDateRange ? selectedDateRange.value : null
        });
        const link = `?company_id=${companyId ?? ''}&tenant_id=${tenantId ?? ''}&department=${departmentParam ?? ''}` +
          `&location=${locationParam ?? ''}&start_date=${startDateParam ?? ''}&end_date=${endDateParam ?? ''}` + 
          `&date_range=${dateRangeParam ?? ''}&group_id=${groupIdParam ?? ''}`;
        const responseSummaryData = await request('/back_office/api/analytics/summary' + link, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptSummaryData = decryptData(responseSummaryData);

        if (!Object.keys(decryptSummaryData).length || !decryptSummaryData?.totalUsersCount) {
          setNoData(true);
          return decryptSummaryData;
        }
        setSummaryData(decryptSummaryData);
        setFilteredMinMailingDate(decryptSummaryData.filteredMinMailingDate);

        let filteredTenants = contextTenants;
        if (selectedDepartment || selectedLocation) {
          filteredTenants = filteredTenants?.filter(tenant =>
            decryptSummaryData?.tenantIds?.includes(tenant.id)
          );
        }

        let filteredDepartments = contextDepartments;
        if (selectedTenant || selectedLocation) {
          filteredDepartments = filteredDepartments?.filter(department =>
            decryptSummaryData?.departments?.includes(department.label)
          );
        }

        let filteredLocations = contextLocations;
        if (selectedTenant || selectedDepartment) {
          filteredLocations = filteredLocations?.filter(location =>
            decryptSummaryData?.locations?.includes(location.label)
          );
        }

        contextTenants?.length && setTenants(filteredTenants);
        contextDepartments?.length && setDepartments(filteredDepartments);
        contextLocations?.length && setLocations(filteredLocations);

        return decryptSummaryData;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedTenant, selectedDepartment, selectedLocation, selectedGroup, minMailingDate, maxMailingDate, dateRangeParam]);

  useEffect(() => {
    if (departmentParam) {
      setSelectedDepartment({ value: departmentParam, label: departmentParam });
    }
    if (locationParam) {
      setSelectedLocation({ value: locationParam, label: locationParam });
    }
    if (groupIdParam) {
      const groupName = groupNameParam || groupIdParam;
      setSelectedGroup({ value: groupIdParam, label: groupName });
    }
    if (tenantId) {
      const tenantName = contextTenants.find(tenant => tenant.id === tenantId)?.label;
      setSelectedTenant({ id: tenantId, value: tenantId, label: tenantName });
    }
    if (startDateParam) {
      setMinMailingDate(startDateParam);
    }
    if (endDateParam) {
      setMaxMailingDate(endDateParam);
    }
    if (dateRangeParam) {
      setSelectedDateRange(dateRangeOptionsList.find(option => option.value === +dateRangeParam));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSummaryData();
  }, [fetchSummaryData]);

  return (
    <>
      <UserAnalyticsFilterBar
        loading={loading}
        tenants={tenants}
        departments={departments}
        locations={locations}
        minMailingDate={minMailingDate}
        setMinMailingDate={setMinMailingDate}
        selectedTenant={selectedTenant}
        setSelectedTenant={handleTenantChange}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        dateRangeOptionsList={dateRangeOptionsList}
        filteredMinMailingDate={filteredMinMailingDate}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        setDateRangeOptionsList={setDateRangeOptionsList}
        maxMailingDate={maxMailingDate}
        setMaxMailingDate={setMaxMailingDate}
        generateReportCallback={generateReport}
        generateReportButtonDisabled={disabled}
        generateReportButtonVisible={!noData}
        setSelectedGroup={handleGroupChange}
        selectedGroup={selectedGroup}
        groupIdParam={groupIdParam}
        setNoData={setNoData}
      />

      {noData ?
        <div className="no-groups-message">
          No data available.
          <span className="group-link">
            <button onClick={clearGroup}>Clear filter</button>
          </span>
        </div>
        : <>
          <SummaryMetrics
            summaryData={summaryData}
            loading={loading}
          />

          <div className='trend-graphs-container'>
            <TrendGraph
              title='Phish Click Trend Graph'
              loading={loading}
              minMailingDate={minMailingDate}
              maxMailingDate={maxMailingDate}
              graphData={summaryData?.phishClickTrendGraphData || {}}
              currValue={summaryData?.phishClickRate || '0'}
              diffValue={summaryData?.diffPhishClickRate || 0}
              tooltipName={'Phish Click Rate'}
              percentage={true}
              plusIsPositive={false}
              stepSizeY={10}
            />
            <TrendGraph
              highlited={true}
              title='Human Risk Rate Graph'
              loading={loading}
              minMailingDate={minMailingDate}
              maxMailingDate={maxMailingDate}
              graphData={summaryData?.breachRiskTrendGraphData || {}}
              currValue={summaryData?.phishRisk || '0'}
              diffValue={summaryData?.diffPhishRisk || 0}
              tooltipName={'Human Risk Rate'}
              percentage={true}
              plusIsPositive={false}
              stepSizeY={10}
              percentageTicks={true}
            />
          </div>

          <div className={cn('trend-graphs-container', {
            'trend-graphs-container--loading': loading
          })}>
            <TrendGraph
              highlited={true}
              title='Educational Engagement Trend Graph'
              loading={loading}
              minMailingDate={minMailingDate}
              maxMailingDate={maxMailingDate}
              graphData={summaryData?.engagementTrendGraphData || {}}
              currValue={summaryData?.engagementRate || '0'}
              diffValue={summaryData?.diffEngagementRate || 0}
              tooltipName={'Engagement Rate'}
              percentage={true}
              plusIsPositive={true}
            />
            <TrendGraph
              title='Reporting Trend Graph'
              loading={loading}
              minMailingDate={minMailingDate}
              maxMailingDate={maxMailingDate}
              graphData={summaryData?.reportingTrendGraphData || {}}
              currValue={summaryData?.phishSimulationsReportRate || '0'}
              diffValue={summaryData?.diffPhishSimulationsReportRate || 0}
              tooltipName={'Reporting Rate'}
              percentage={true}
              plusIsPositive={true}
            />
          </div>

          {!loading
            ? <div className='prone-tables-container'>
              <ProneTable tablesData={summaryData?.tablesData} />
            </div>
            : <Loader custom={true} />
          }
        </>}
    </>
  );
};
