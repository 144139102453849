import React, { useEffect, useRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import cn from 'classnames';
import { Loader } from '../../Loader/Loader';
import { ReactComponent as Description } from '../../../images/icons/description.svg';
import { ReactComponent as PDFDownload } from '../../../images/icons/download-pdf.svg';
import { CustomizedTooltipFalsePositive } from '../../Tooltips/Tooltip';
import './ProneTable.css';

export const TemplateProneTable = ({
  title, columns, data, loading, callback, downloadLoading, height,
  downloadCertificateCallback, downloadCertificateLoading
}) => {
  const cellRefs = useRef([]);

  useEffect(() => {
    const setDynamicTitle = () => {
      cellRefs.current.forEach(cellRef => {
        if (cellRef) {
          const containerWidth = cellRef.offsetWidth;
          const textWidth = cellRef.scrollWidth;

          if (textWidth > containerWidth) {
            cellRef.setAttribute('title', cellRef.textContent);
          } else {
            cellRef.removeAttribute('title');
          }
        }
      });
    };

    setDynamicTitle();
    window.addEventListener('resize', setDynamicTitle);

    return () => window.removeEventListener('resize', setDynamicTitle);
  }, [data]);

  const Row = ({ index, style }) => {
    const row = data[index];
    return (
      <tr key={index} style={{...style, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        {row.map((cell, cellIndex) => {
          let cellContent = cell;
          if (Array.isArray(cell)) {
            cellContent = `${cell[0]?.toString()?.replace(' Risk', '')} ${cell[1]}`;
          } else {
            cellContent = cell
              ?.toString()
              ?.replace(' Risk', '')
              ?.replace('Download certificate', '');
          }

          return (
            <td
              className={cn({
                'high-risk-user': cell === 'High Risk'
                  || cell === 'No interaction'
                  || cell === 'Non-Compliant',
                'moderate-risk-user': cell === 'Moderate Risk',
                'low-risk-user': cell === 'Low Risk'
                  || cell === 'Compliant',
              })}
              key={cellIndex}
              style={{ width: `${100 / columns?.length}%` }}
              ref={ref => (cellRefs.current[index * columns.length + cellIndex] = ref)}
            >
              <span>
                {cellContent}
              </span>
              {downloadCertificateCallback &&
                cell === 'Download certificate' && (
                  <CustomizedTooltipFalsePositive
                    showFragment={true}
                    position='top'
                    text1={`Download a certificate of successful completion of ${row[0]} Training?`}
                    component={
                      <button
                        onClick={() => downloadCertificateCallback(row)}
                        className="download-certificate"
                        disabled={downloadCertificateLoading}
                      >
                        <PDFDownload />
                      </button>
                    }
                  />
                )}
            </td>
          );
        })}
      </tr>
    );
  };

  if (!height) height = data.length ? data.length * 51 : 50;
 
  return (
    <div className="table-container">
      {loading ? (
        <Loader custom={true} />
      ) : (
        <>
          <div className="table-title-container">
            <h3>{title}</h3>
            {data?.length && callback ? (
              !downloadLoading ? (
                <button onClick={callback} disabled={downloadLoading}>
                  <Description />
                  Export CSV
                </button>
              ) : (
                <button
                  className="csv-download-loading"
                  type="button"
                  disabled={downloadLoading}
                >
                  <span className="mini-loader-csv"></span>
                </button>
              )
            ) : (
              <div></div>
            )}
          </div>
          {data?.length ? (
            <table>
              <thead>
                <tr>
                  {columns.map((col, index) => (
                    <th key={index} style={{ width: `${100 / columns?.length}%` }}>
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <List
                  height={height}
                  itemCount={data.length}
                  itemSize={50}
                  width={`${columns?.length * 100}%`}
                >
                  {Row}
                </List>
              </tbody>
            </table>
          ) : (
            <div className="no-data-available">No data available</div>
          )}
        </>
      )}
    </div>
  );
};
