import React, { useContext, useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import moment from 'moment';
import ReactSelect from '../../ReactSelect/ReactSelect';
import { DateRangeOption, DepartmentOption, LocationOption, TenantOption } from '../../ReactSelect/ReactSelectOption';
import { AnalyticsGeneralContext } from '../../../context/AnalyticsGeneralContext';
import { dateRangeOptionsAnalytics, reportScheduleSendingFrequencyOptions, lastThirtyDaysChunk, syncOptions } from '../../../config/config';
import { AllUsersOption } from '../../ReactSelect/ReactSelectOption';
import { ReactComponent as Description } from '../../../images/icons/description.svg';
import { AuthContext } from '../../../context/AuthContext';
import { customModalStyles } from '../../../config/config';
import { useCrypto } from '../../../hooks/crypto.hook';
import { useHttp } from '../../../hooks/http.hook';
import { GroupsSelect } from '../../GroupsSelect/GroupsSelect';
import { CustomizedTooltipDisabled } from '../../Tooltips/Tooltip';
import './GenerateReportModal.css';

export const GenerateReportModal = ({
  isOpen, onRequestClose, tenants, selectedTenant, setSelectedTenant, departments, selectedDepartment,
  setSelectedDepartment, locations, selectedLocation, setSelectedLocation, selectedDateRange,
  setSelectedDateRange, setMinMailingDate, setMaxMailingDate, selectedGroup, setSelectedGroup,
  groupIdParam, loading, generateReportButtonDisabled
}) => {
  const { decryptData, encryptData } = useCrypto();
  const { request, requestWithSecondaryLoading } = useHttp();
  const { token, allUsers, user, showToastMessage } = useContext(AuthContext);
  const { minMailingDate: contextMinMailingDate } = useContext(AnalyticsGeneralContext);

  
  const syncTypes = syncOptions.map(type => type.value);
  const updatedDateRangeOptions = dateRangeOptionsAnalytics.slice(0, -1);

  const [admins, setAdmins] = useState([]);
  const [selectedReceivers, setSelectedReceivers] = useState([]);
  const [usersOptionList, setUsersOptionList] = useState([]);
  const [adminsOptionList, setAdminsOptionList] = useState([]);

  const [selectedReportFrequency, setSelectedReportFrequency] = 
    useState(reportScheduleSendingFrequencyOptions[0]);
  const [checkedItems, setCheckedItems] = useState({
    summaryPageSelect: false,
    riskPageSelect: false,
    phishingPageSelect: false,
    educationPageSelect: false,
  });

  const handleChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    const optionList = allUsers
      .filter(user =>
        selectedTenant ? user.tenant.id === selectedTenant.value : user &&
        selectedDepartment ? user.department === selectedDepartment.value : user &&
        selectedLocation ? user.location === selectedLocation.value : user)
      .map(user => ({
        ...user,
        value: user.id,
        label: user.email,
        caption: user.firstName + ' ' + user.secondName,
      }));
    setUsersOptionList(
      optionList
      .filter(user => !selectedReceivers.some(receiverSelected => receiverSelected?.email === user?.email))
    );

  }, [allUsers, selectedReceivers, selectedTenant, selectedDepartment, selectedLocation]);

  useEffect(() => {
    const optionList = admins
      .filter(admin => {
        if (admin.role === 'owner') return admin
        else if (
          (admin.role === 'admin' || admin.role === 'tenant-admin') &&
          selectedTenant && admin?.tenants?.tenantsIds?.includes(selectedTenant.value) 
        ) return admin
        return null
      })
      .filter(Boolean)
      .map(user => ({
        ...user,
        value: user.id,
        label: user.email,
        caption: user.firstName + ' ' + user.lastName,
      }));
    setAdminsOptionList(
      optionList
      .filter(user => !selectedReceivers.some(receiverSelected => receiverSelected?.email === user?.email))
    );

  }, [allUsers, selectedReceivers, selectedTenant, selectedDepartment, selectedLocation, admins]);

  const fetchAccountAdmins = useCallback(async () => {
    try {
      if (token) {
        const users = await request('/back_office/api/user/account_users', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptUsers = decryptData(users);
        setAdmins(decryptUsers);

        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const createReportSchedule = async (receivers, companyId, tenantId, groupId, groupName, department, location, dateRange,
    summaryPageSelect, riskPageSelect, phishingPageSelect, educationPageSelect, sendingFrequencyId) => {
    try {
      onRequestClose();
      const data = encryptData({receivers, companyId, tenantId, groupId, groupName, department, location, dateRange,
        summaryPageSelect, riskPageSelect, phishingPageSelect, educationPageSelect, sendingFrequencyId});
      const res = await requestWithSecondaryLoading('/back_office/api/analytics/create_report_schedule', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })

      showToastMessage(res.error, res.message);

      // if (callback) callback();
    } catch (error) { }
  };

  useEffect(() => {
    fetchAccountAdmins()
  }, [fetchAccountAdmins]);

  const handleUserSelect = (data) => {
    setSelectedReceivers(prev => [...prev, data]?.filter(Boolean));
  };

  const handleAdminSelect = (data) => {
    setSelectedReceivers(prev => [...prev, data]?.filter(Boolean));
  };

  const removeSelectedReceivers = (index) => {
    setSelectedReceivers(prev => prev.filter((_, i) => i !== index));
  };

  const resetDateRange = useCallback(() => {
    setSelectedDateRange(dateRangeOptionsAnalytics[0]);
    setMinMailingDate(moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD'));
  }, [setSelectedDateRange, setMinMailingDate]);

  const handleDepartmentChange = useCallback((data) => {
    setSelectedDepartment(data);
    resetDateRange();
  }, [resetDateRange, setSelectedDepartment]);

  const handleLocationChange = useCallback((data) => {
    setSelectedLocation(data);
    resetDateRange();
  }, [resetDateRange, setSelectedLocation]);

  const handleTenantChange = useCallback((data) => {
    setSelectedTenant(data);
    setSelectedGroup(null);
    resetDateRange();
  }, [resetDateRange, setSelectedTenant, setSelectedGroup]);

  const handleGroupChange = useCallback((data) => {
    setSelectedGroup(data);
    resetDateRange();
  }, [resetDateRange, setSelectedGroup]);

  const handleDateRangeChange = useCallback((data) => {
    if (!data || data?.value === 0) {
      setMinMailingDate(contextMinMailingDate);
    } else {
      setMinMailingDate(moment().subtract(data.value, 'days').format('YYYY-MM-DD'));
    }
    setMaxMailingDate(moment().format('YYYY-MM-DD'));
    setSelectedDateRange(data);
  }, [contextMinMailingDate, setMaxMailingDate, setMinMailingDate, setSelectedDateRange]);

  const handleReportFrequencyChange = useCallback((data) => {
    setSelectedReportFrequency(data);
  }, [setSelectedReportFrequency]);
      
  const closeModal = () => {
    onRequestClose();
    setSelectedReceivers([]);
    setCheckedItems({
      summaryPageSelect: false,
      riskPageSelect: false,
      phishingPageSelect: false,
      educationPageSelect: false,
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel='Frequency Modal'
      style={customModalStyles}
    >
      <div className='frequency-modal'>
        <div className='frequency-content'>
          <span className='select-pages-title'>Select filtering options</span>
          <div className='select-container'>
            <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--tenant report-modal-select', {
              'input-container-analytics-item--disabled': loading
            })}>
              <ReactSelect
                options={tenants}
                optionComponent={TenantOption}
                placeholder={'Tenant'}
                classNames={'option-select-analytics'}
                value={selectedTenant}
                onChange={handleTenantChange}
                isSearchable={true}
                isClearable={true}
                optionHeight={30}
                rows={10}
              />
            </div>
            <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--group report-modal-select', {
              'input-group-container-analytics-item--disabled': loading || !selectedTenant?.value || user.paymentPlanId !== 4
            })}>
            {(syncTypes?.includes(selectedTenant?.syncType) || groupIdParam)
              ? <GroupsSelect
                selectedTenant={selectedTenant}
                classNames={'option-select-analytics'}
                isMulti={false}
                onChange={handleGroupChange}
                selectedGroup={selectedGroup}
                placeholder={'Group'}
                isDisabled={!selectedTenant?.value}
              />
              : <CustomizedTooltipDisabled
                position={"top"}
                text1={user.paymentPlanId !== 4 ? "Upgrade" : "Please select your Azure"}
                text2={user.paymentPlanId !== 4 ? "required" : "or Google tenant first."}
                button={
                  <GroupsSelect
                    selectedTenant={selectedTenant}
                    classNames={'option-select-analytics'}
                    isMulti={false}
                    onChange={handleGroupChange}
                    selectedGroup={selectedGroup}
                    placeholder={'Group'}
                    isDisabled={!selectedTenant?.value}
                  />
                }
              />
            }
            </div>
          </div>
          <div className='select-container'>
            <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--department report-modal-select', {
              'input-container-analytics-item--disabled': loading
              })}>
              <ReactSelect
                options={departments}
                optionComponent={DepartmentOption}
                placeholder={'Department'}
                classNames={'option-select-analytics'}
                value={selectedDepartment}
                onChange={handleDepartmentChange}
                isSearchable={true}
                isClearable={true}
                optionHeight={32}
                rows={10}
              />
            </div>
            <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--location report-modal-select', {
              'input-container-analytics-item--disabled': loading
              })}>
              <ReactSelect
                options={locations}
                optionComponent={LocationOption}
                placeholder={'Location'}
                classNames={'option-select-analytics'}
                value={selectedLocation}
                onChange={handleLocationChange}
                isSearchable={true}
                isClearable={true}
                optionHeight={32}
                rows={10}
              />
            </div>
          </div>
            <div className='select-container'>
              <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--date-range report-modal-select', {
                'input-container-analytics-item--disabled': loading
              })}>
                <ReactSelect
                  options={updatedDateRangeOptions}
                  optionComponent={DateRangeOption}
                  placeholder={'Date Range'}
                  onChange={handleDateRangeChange}
                  value={selectedDateRange}
                  isSearchable={false}
                  isDisabled={loading}
                  optionHeight={28}
                  rows={10}
                />
              </div>
              <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--date-range report-modal-select', {
                'input-container-analytics-item--disabled': loading
              })}>
                <ReactSelect
                  options={reportScheduleSendingFrequencyOptions}
                  optionComponent={DateRangeOption}
                  placeholder={'Report Frequency'}
                  onChange={handleReportFrequencyChange}
                  value={selectedReportFrequency}
                  isSearchable={false}
                  isDisabled={loading}
                  optionHeight={28}
                  rows={10}
                />
              </div>
            </div>

          <span className='select-pages-title'>Select analytics report pages</span> 
          <div className="select-pages-container">
            <label className={"select-pages-label"}>
              <input
                type="checkbox"
                name="summaryPageSelect"
                checked={checkedItems.summaryPageSelect}
                onChange={handleChange}
                className="select-pages-input"
                disabled={generateReportButtonDisabled}
              />
              Summary
            </label>
            <label className={"select-pages-label"}>
              <input
                type="checkbox"
                name="riskPageSelect"
                checked={checkedItems.riskPageSelect}
                onChange={handleChange}
                className="select-pages-input"
                disabled={generateReportButtonDisabled}
              />
              Risk
            </label>
            <label className={"select-pages-label"}>
              <input
                type="checkbox"
                name="phishingPageSelect"
                checked={checkedItems.phishingPageSelect}
                onChange={handleChange}
                className="select-pages-input"
                disabled={generateReportButtonDisabled}
              />
              Phishing
            </label>
            <label className={"select-pages-label"}>
              <input
                type="checkbox"
                name="educationPageSelect"
                checked={checkedItems.educationPageSelect}
                onChange={handleChange}
                className="select-pages-input"
                disabled={generateReportButtonDisabled}
              />
              Education
            </label>
          </div>

          <span className='card-title'>Select analitycs report receivers</span>
          <div className='select-container'>
            <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--user report-modal-select')}>
              <ReactSelect
                options={adminsOptionList}
                optionComponent={AllUsersOption}
                classNames={cn({'user-select--disabled': loading})}
                placeholder={'Select Admin...'}
                value={null}
                onChange={handleAdminSelect}
                isSearchable={true}
                isClearable={true}
                isDisabled={loading}
                optionHeight={50}
                rows={6}
                isLoading={loading}
              />
            </div>

            <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--user report-modal-select')}>
              <ReactSelect
                options={usersOptionList}
                optionComponent={AllUsersOption}
                classNames={cn({'user-select--disabled': loading})}
                placeholder={'Select User...'}
                value={null}
                onChange={handleUserSelect}
                isSearchable={true}
                isClearable={true}
                isDisabled={loading}
                optionHeight={50}
                rows={6}
                isLoading={loading}
              />
            </div>
          </div>

          <div className='report-receivers'>
            {!!selectedReceivers.length && <span className='card-title'>Report receivers:</span>}
            {selectedReceivers?.map((user, index) => (
              <div key={index} className='analytics-report-item'>
                <div>
                  <span className='analytics-report-item-name'>{user?.firstName} {user?.secondName || user?.lastName}</span>
                  <span className='analytics-report-item-email'>{user?.email}</span>
                </div>
                {!generateReportButtonDisabled && 
                  <i
                    role='button'
                    onClick={() => removeSelectedReceivers(index)}
                    className={cn({ 'tenants-clear-disabled': false})}
                  />
                }
              </div>
            ))}
          </div>

          <div className='analytics-report-button modal-button'>
            {!generateReportButtonDisabled ? (
              <button
                className='generate-report-button'
                onClick={async () => {
                  const res = await createReportSchedule(
                    selectedReceivers,
                    user.companyId,
                    selectedTenant?.value,
                    selectedGroup?.value,
                    selectedGroup?.label,
                    selectedDepartment?.value,
                    selectedLocation?.value,
                    selectedDateRange?.value,
                    checkedItems.summaryPageSelect, 
                    checkedItems.riskPageSelect, 
                    checkedItems.phishingPageSelect, 
                    checkedItems.educationPageSelect,
                    selectedReportFrequency?.value
                  );
                  if (res) closeModal();
                }}
                disabled={
                  generateReportButtonDisabled ||
                  !selectedReceivers.length ||
                  (checkedItems.summaryPageSelect === false && 
                    checkedItems.riskPageSelect === false && 
                    checkedItems.phishingPageSelect === false && 
                    checkedItems.educationPageSelect === false
                  )
                }
              >
              <Description />
                Create Report Schedule
              </button>
            ) : (
              <button
                className="csv-download-loading"
                type="button"
                disabled={
                  generateReportButtonDisabled ||
                  !selectedReceivers.length ||
                  (checkedItems.summaryPageSelect === false && 
                    checkedItems.riskPageSelect === false && 
                    checkedItems.phishingPageSelect === false && 
                    checkedItems.educationPageSelect === false
                  )
                }
              >
                <span className="mini-loader-csv"></span>
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
