/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import colourStyles from '../../styles/colour-style';
import Select, { components } from 'react-select';
import { useCrypto } from '../../hooks/crypto.hook';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { syncOptions } from '../../config/config';
import { GroupsSelect } from '../GroupsSelect/GroupsSelect';
import { customModalStyles } from '../../config/config';
import './CustomModal.css';

export const SyncModal = (props) => {
  const {
    groupName, setSyncModal, syncModal,
    setIsLoading, tenant: editedTenant,
    navigaveToGroup = true, onMappingCreated
  } = props;
  const { request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token } = useAuth();
  const navigate = useNavigate();
  const { decryptData, encryptData } = useCrypto();
  const { showToastMessage, allGroups, fetchSystemGroupsList } = useContext(AuthContext);
  const [systemGroupsOptions, setSystemGroupsOptions] = useState([]);
  const [selectedTOGroup, setTOGroupGroup] = useState(
    groupName ? {
      label: groupName.split('_').join(' '),
      value: groupName,
    } : null);
  const [selectedSyncType, setSyncType] = useState(syncOptions[0]);
  const [selectedSyncGroups, setSelectedSyncGroups] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState({});
  const [exclusions, setExclusions] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [syncTypes, setSyncTypes] = useState(syncOptions);
  const [step, setStep] = useState(1);
  const [remoteGroupUsers, setRemoteGroupUsers] = useState({});
  const [remoteGroups, setRemoteGroups] = useState([]);
  const [excludedUsersOptions, setExcludedUsersOptions] = useState([]);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);
  const normalizedGroupName = groupName?.split('_').join(' ');
  const group = allGroups.find(e => e.label === normalizedGroupName);

  const syncOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')} users-list-tenant-label`}>
          {label}
        </div>
      </components.Option>
    );
  };

  const fetchRemoteGroups = useCallback(async () => {
    if (selectedTenant?.value && token && group) {
      const fetched = await request(
        `/back_office/api/groups/sync_group_list?tenantId=${selectedTenant?.value}`, 'GET', null, {
        Authorization: `Bearer ${token}`,
      })

      const groups = decryptData(fetched)
      setRemoteGroups(groups);

      const tenant = tenants.find((t) => t.id === selectedTenant.value);
      const selectedRemoteGroupIds = tenant?.groupMapping.filter((mapping) => mapping.groupId === group.id)
        ?.map((g) => g.remoteGroupId);

      const selectedGroups = groups.filter((e) => selectedRemoteGroupIds?.includes(e.id))
      const options = selectedGroups.map((e) => {
        return {
          value: e.id,
          label: e.name,
        }
      })
      setSelectedSyncGroups(options)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, selectedTenant.id, group]);

  const tenantsOption = (props) => {
    const { label } = props.data;

    return (
      <components.Option {...props}>
        <div className={`users-list-tenant-label`}>{label}</div>
      </components.Option>
    );
  };

  const syncGroupsOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')} users-list-tenant-label`}>
          {label}
        </div>
      </components.Option>
    );
  };

  const handleSyncTypeChange = (data) => {
    setSyncType(data);
    setRemoteGroupUsers({});
    handleTenantChange(
      data.value === 'azure' ? azureTenantsOptions[0] : googleTenantsOptions[0]
    );
  };

  const handleTenantChange = (data) => {
    setRemoteGroupUsers({});
    setSelectedTenant(data);
    const tenant = tenants?.find((t) => t.name === data.label);
    const groups = getMappedGroupsForTenant(tenant?.groupMapping, group?.id);

    setSelectedSyncGroups(groups);
  };

  function handleTOGroupSelect(data) {
    setTOGroupGroup(data);
  }

  const handleSyncGroupsChange = (data) => {
    setSelectedSyncGroups(data);
  };

  const handleEditExclusionsChange = (data) => {
    setExclusions(data);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      await changeGroupMapping();
      await addExclusions(selectedTOGroup.label, exclusions);
      fetchSystemGroupsList();

      if (onMappingCreated) {
        onMappingCreated();
      }
      if (navigaveToGroup) {
        navigate(`/back_office/groups/${selectedTOGroup.label.split(' ').join('_')}`);
      }
      closeModal();
    } catch (error) {
      showToastMessage(true, 'An error occurred during sync');
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setRemoteGroupUsers({})
    setStep(1)
    setSyncModal(false);
  }

  const changeGroupMapping = async () => {
    const selectedData = {
      selectedSyncGroups,
      destGroupName: selectedTOGroup.label,
      tenantId: selectedTenant.id,
    };

    if (token) {
      const data = encryptData(selectedData);
      const response = await request(
        `/back_office/api/groups/create_groups_map`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      showToastMessage(response.error, response.message);
    }
  };

  const getExclusions = useCallback(
    async () => {
      try {
        if (token && selectedTOGroup.label) {
          const fetched = await requestWithSecondaryLoading(
            `/back_office/api/groups/${selectedTOGroup.label
              .split(' ')
              .join('_')}/exclusions`,
            'GET',
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );
          const decryptExclusions = decryptData(fetched);
          const filteredExclusions = decryptExclusions
            .filter((u) => u.tenantId === selectedTenant.id && u.syncType === selectedSyncType.value);
          const exclusionOptions = getUsersOptions(filteredExclusions);

          setExclusions(exclusionOptions);

          return decryptExclusions;
        }
      } catch (error) { }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, request, selectedTenant]
  );

  const getRemoteGroupUsers = useCallback(async () => {
    try {
      if (token) {
        const addedGroups = {};
        setIsGroupsLoading(true)

        for (const group of selectedSyncGroups) {
          const groupId = group.value;
          if (remoteGroupUsers[groupId]) continue;

          const fetched = await requestWithSecondaryLoading(
            `/back_office/api/groups/remote_group_users?groupId=${groupId}`,
            'GET',
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );
          const users = decryptData(fetched);

          addedGroups[groupId] = users;
        }
        setRemoteGroupUsers((prev) => ({ ...prev, ...addedGroups }));
        setIsGroupsLoading(false)
      }

    } catch (error) {
      setIsGroupsLoading(false)
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token, request, selectedSyncGroups])

  const getAllTenants = useCallback(async () => {
    try {
      if (token && !editedTenant) {
        const fetched = await request(
          '/back_office/api/user/get_all_tenants',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptTenants = decryptData(fetched).filter((t) => t.type !== 'csv');
        setTenants(decryptTenants);
        const syncTypesOptions = decryptTenants.map((t) => t.type);
        const syncOptions = syncTypes.filter((t) =>
          syncTypesOptions.includes(t.value)
        );
        setSyncTypes(syncOptions);

        // Selected tenant by default
        const defaultTenant = decryptTenants?.find(
          (t) => t.groupMapping.find((g) => g.groupId === group?.id)
        ) || decryptTenants[0];

        if (defaultTenant) {
          setSyncType(syncOptions.find((t) => t.value === defaultTenant?.type));

          setSelectedTenant({
            value: defaultTenant?.id,
            label: defaultTenant?.name,
            id: defaultTenant?.id,
            type: defaultTenant?.type,
          });
        }

        return tenants;
      } else if (token && editedTenant) { // Sync modal on Mapping page - edit group mapping
        const syncType = syncOptions.find((t) => t.value === editedTenant.type.split(' ').join('_'));
        setSyncType(syncType)
        setSelectedTenant({
          value: editedTenant?.id,
          label: editedTenant?.name,
          id: editedTenant?.id,
          type: editedTenant?.type,
        });

        const groups = getMappedGroupsForTenant(
          editedTenant.groupMapping,
          group?.id
        );
        setSelectedSyncGroups(groups);

        setTOGroupGroup({
          label: group?.label,
          value: group?.label.split(' ').join('_'),
        })
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, editedTenant, groupName, remoteGroups]);

  const azureTenants = tenants?.filter((t) => t.type === 'azure');
  const googleTenants = tenants?.filter((t) => t.type === 'google');

  const azureTenantsOptions = azureTenants?.map((e, i) => {
    return { value: e.id, label: e.name, id: e.id, type: e.type };
  });
  const googleTenantsOptions = googleTenants?.map((e, i) => {
    return { value: e.id, label: e.name, id: e.id, type: e.type };
  });

  const getMappedGroupsForTenant = (groupMapping, groupId) => {
    const selectedRemoteGroupIds = groupMapping.filter((mapping) => mapping.groupId === groupId)
      ?.map((g) => g.remoteGroupId);

    const groups = remoteGroups.filter((e) => selectedRemoteGroupIds.includes(e.id))
    const options = groups.map((e) => {
      return {
        value: e.id,
        label: e.name,
      }
    })

    return options;
  };

  const getUsersOptions = (users) => {
    const options = users?.map((u) => {
      return {
        value: u.email,
        label: `${u.firstName} ${u.secondName} <${u.email}>`,
        key: u.email,
      };
    });

    return options;
  };

  const getUsersFromGroups = () => {
    const groupIds = selectedSyncGroups?.map((e) => e.value);
    const users = groupIds?.map((g) => remoteGroupUsers[g]).filter(Boolean).flat();
    const options = [];

    for (const user of users) {
      if (options.find((e) => e.value === user.email)) continue;
      options.push({
        value: user.email,
        label: `${user.firstName} ${user.secondName} <${user.email}>`,
        key: user.email,
      });
    }

    return options;
  };

  const addExclusions = async (groupName, users) => {
    try {
      const payload = {
        selectedUsers: users.map((u) => u.value),
        tenant: { name: selectedTenant.label, id: selectedTenant.id, type: selectedSyncType.value },
      };

      if (token && selectedTOGroup.label) {
        await request(
          `/back_office/api/groups/${groupName
            .split(' ')
            .join('_')}/exclusions`,
          'POST',
          payload,
          {
            Authorization: `Bearer ${token}`,
          }
        );
      }
    } catch (error) { }
  };

  const fetchSystemGroupsOptionsList = useCallback(async () => {
    try {
      if (groupName) {
        setTOGroupGroup({
          label: group?.label,
          value: group?.label.split(' ').join('_'),
        })
      } else if (token) {
        const fetched = await request(
          '/back_office/api/groups/group_options_list',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptGroups = decryptData(fetched);
        setSystemGroupsOptions(decryptGroups);

        return decryptGroups;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, groupName]);

  useEffect(() => {
    fetchSystemGroupsOptionsList();
  }, [fetchSystemGroupsOptionsList]);

  useEffect(() => {
    getAllTenants();
  }, [getAllTenants]);

  useEffect(() => {
    getExclusions();
  }, [getExclusions]);

  useEffect(() => {
    getRemoteGroupUsers();
  }, [getRemoteGroupUsers]);

  useEffect(() => {
    fetchRemoteGroups();
  }, [fetchRemoteGroups]);

  const Option = (props) => {
    const { label, caption } = props.data;

    return (
      <components.Option {...props}>
        <div className="new-group-users-table-label">{label}</div>
        <div className="group-caption">{caption}</div>
      </components.Option>
    );
  };

  return (
    <Modal
      isOpen={syncModal}
      onRequestClose={() => closeModal()}
      style={customModalStyles}
      contentLabel="Sync groups modal"
    >
      <form className="form modal-form">
        <div className="title modal-title">
          Sync with integration groups
          <br></br>
        </div>
        <div className="card-content modal-content">
          {step === 1 && <>
            <div className="new-group-select">
              <label className="userslist-select-label">
                Sync method
                <Select
                  id="select"
                  options={syncTypes}
                  components={{ syncOption }}
                  placeholder="Select sync type"
                  value={selectedSyncType}
                  onChange={handleSyncTypeChange}
                  isSearchable={true}
                  styles={colourStyles}
                  isDisabled={!!editedTenant}
                />
              </label>
            </div>
            <div className="new-group-select">
              <label className="userslist-select-label">
                Tenant
                <Select
                  id="select"
                  options={
                    selectedSyncType?.value === 'azure'
                      ? azureTenantsOptions
                      : googleTenantsOptions
                  }
                  components={{ tenantsOption }}
                  placeholder="Select sync type"
                  value={selectedTenant}
                  onChange={handleTenantChange}
                  isSearchable={true}
                  styles={colourStyles}
                  isDisabled={!!editedTenant}
                />
              </label>
            </div>
            <strong>FROM</strong>
            <div className="new-group-select">
              <label className="userslist-select-label">
                Choose group
                {selectedSyncType?.label ? ' in ' + selectedSyncType.label : ''}
                <GroupsSelect
                  selectedTenant={{ ...selectedTenant, syncType: selectedSyncType.value }}
                  isMulti={true}
                  onChange={handleSyncGroupsChange}
                  selectedGroup={selectedSyncGroups}
                  placeholder={'Select Groups'}
                  isLoading={isGroupsLoading || secondaryLoading}
                  isDisabled={secondaryLoading || !selectedTenant?.value}
                />
              </label>
            </div>
            <strong>TO</strong>
            <div className="new-group-select">
              <label className="userslist-select-label">
                <Select
                  id="select"
                  options={systemGroupsOptions}
                  components={{ Option }}
                  placeholder="Select group"
                  value={selectedTOGroup}
                  onChange={handleTOGroupSelect}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  styles={colourStyles}
                  isDisabled={!!groupName} />
              </label>
            </div>
          </>}
          {step === 2 && <>
            <div className="new-group-select">
              <label className="userslist-select-label">
                Excluded users
                <Select
                  id="select"
                  options={excludedUsersOptions}
                  components={{ syncGroupsOption }}
                  placeholder="Select users to exclude"
                  value={exclusions}
                  onChange={handleEditExclusionsChange}
                  isSearchable={true}
                  styles={colourStyles}
                  isMulti
                />
              </label>
            </div>
          </>}
        </div>

        {step === 1 && <div className="modal-button-container">
          <button
            className="group-button-secondary"
            onClick={() => closeModal()}
          >
            Cancel
          </button>

          <button
            className="group-button group-button-simple"
            disabled={isGroupsLoading || !selectedSyncGroups?.length || secondaryLoading || !selectedTOGroup}
            onClick={() => {
              const users = getUsersFromGroups()
              setExcludedUsersOptions(users)
              setStep(2)
            }}
          >
            Next
          </button>
        </div>}
        {step === 2 && <div className="modal-button-container">
          <button
            className="group-button-secondary"
            onClick={() => setStep(1)}
          >
            Back to group selection
          </button>

          <button
            className="group-button group-button-simple"
            disabled={!selectedSyncGroups?.length}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>}
      </form>
    </Modal>
  );
};
