/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import { useSortableData } from '../../hooks/sort.hook';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import colourStyles from '../../styles/colour-style';
import cross from '../../images/icons/Vector.svg';
import Select, { components } from 'react-select';
import { useCrypto } from '../../hooks/crypto.hook';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import './NewUsersGroup.css';
import submit from '../../images/icons/Check.svg';
import { itemsOnPage } from '../../config/config';
import azure from '../../images/icons/Azure.svg';
import google from '../../images/icons/Google (colors).svg';
import csv from '../../images/icons/File Red.svg';
import { CustomizedTooltipDisabled } from '../../components/Tooltips/Tooltip';
import { SyncModal } from '../../components/Modal/SyncModal.js';

export const NewUsersGroup = () => {
  const { loading, request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const { showToastMessage } = useContext(AuthContext);
  const { allUsers, fetchSystemGroupsList } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [systemGroups, setSystemGroupsOptions] = useState([]);
  const [selectedSystemGroup, setSelectedSystemGroup] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  // const [card, setCard] = useState(1);
  const [syncModal, setSyncModal] = useState(false);
  const [selectedUsersShown, setSelectedUsersShown] = useState(false);
  const navigate = useNavigate();
  const { items, requestSort, sortConfig } = useSortableData(users);
  const ref = useRef();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [tenants, setTenants] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleSelect(data) {
    setSelectedSystemGroup(data);
  }

  const getAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/user/get_all_tenants',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptTenants = decryptData(fetched);
        setTenants(decryptTenants.filter((t) => t.type !== 'csv'));
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const fetchGroupList = useCallback(async () => {
    try {
      if (token) {
        const fetched = await requestWithSecondaryLoading(
          '/back_office/api/groups/group_options_list',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptGroups = decryptData(fetched);

        setSystemGroupsOptions(decryptGroups);

        return decryptGroups;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, requestWithSecondaryLoading]);

  const submitHandler = async (selectedUsers) => {
    try {
      setIsSubmitting(true);
      const selectedIds = selectedUsers ? selectedUsers : selectedRows;
      const data = encryptData(selectedIds);
      const response = await request(
        `/back_office/api/groups/create/${selectedSystemGroup?.label
          ?.split(' ')
          .join('_')}`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      showToastMessage(response.error, response.message);
      navigate(
        `/back_office/groups/${selectedSystemGroup?.label?.split(' ').join('_')}`
      );
      fetchSystemGroupsList();
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const addUsers = async (preparedProspects) => {
    try {
      const data = encryptData(preparedProspects);
      if (token) {
        await requestWithSecondaryLoading(
          `/back_office/api/user/createUsers`,
          'POST',
          { data },
          {
            Authorization: `Bearer ${token}`,
          }
        );
        return users;
      }
    } catch (error) { }
  };

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    let preparedProspects = [];

    if (file) {
      Papa.parse(file, {
        complete: async function (results) {
          let parsedResults = [];
          for (let i = 1; i < results.data.length - 1; i++) {
            let resultsItem = {};
            for (let j = 0; j < results.data[0].length; j++) {
              const entries = Object.fromEntries([
                [results.data[0][j], results.data[i][j]],
              ]);
              resultsItem = { ...resultsItem, ...entries };
            }
            parsedResults.push(resultsItem);
          }
          const emails = [];
          for (const entry of parsedResults) {
            if (!entry['First Name']) {
              const res = {
                warning: 'There is no "First Name" field in the CSV file!',
              };
              return showToastMessage(res.warning);
            }
            if (!entry['First Name'].match(/^[\w- .]{1,50}$/)) {
              const res = {
                warning: `First Name in user: ${entry['First Name'] + ' ' + entry['Last Name']
                  } is not valid!`,
              };
              return showToastMessage(res.warning);
            }
            if (!entry['Last Name']) {
              const res = {
                warning: 'There is no "Last Name" field in the CSV file!',
              };
              return showToastMessage(res.warning);
            }
            if (!entry['Last Name'].match(/^[\w- .]{1,50}$/)) {
              const res = {
                warning: `Last Name in user: ${entry['First Name'] + ' ' + entry['Last Name']
                  } is not valid!`,
              };
              return showToastMessage(res.warning);
            }
            if (!entry['Email Address']) {
              const res = {
                warning: 'There is no "Email Address" field in the CSV file!',
              };
              return showToastMessage(res.warning);
            }
            if (
              !entry['Email Address'].match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
            ) {
              const res = {
                warning: `Email Address in user: ${entry['First Name'] + ' ' + entry['Last Name']
                  } is not valid!`,
              };
              return showToastMessage(res.warning);
            }

            // if (emails.includes(entry['Email Address'])) continue;
            emails.push(entry['Email Address']);
            preparedProspects.push({
              first_name: entry['First Name'],
              last_name: entry['Last Name'],
              email: entry['Email Address'],
            });
          }

          const users = await addUsers(preparedProspects);
          const groupUsers = users
            .filter((u) => emails.includes(u.email))
            .map((u) => u.id);
          await submitHandler(groupUsers);

          setFile(null);
        },
      });
    }
  };

  useEffect(() => {
    if (filterValue.length) {
      setUsers(() =>
        allUsers.filter(
          (u) =>
            String(u.id).includes(filterValue) ||
            u.email?.includes(filterValue?.toLowerCase()) ||
            [u.firstName?.toLowerCase(), u.secondName?.toLowerCase()]
              .join(' ')
              ?.includes(filterValue?.toLowerCase()) ||
            [u.secondName?.toLowerCase(), u.firstName?.toLowerCase()]
              .join(' ')
              ?.includes(filterValue?.toLowerCase()) ||
            u.firstName?.toLowerCase()?.includes(filterValue?.toLowerCase()) ||
            u.secondName?.toLowerCase()?.includes(filterValue?.toLowerCase())
        )
      );
    } else {
      setUsers(allUsers);
    }
  }, [filterValue, allUsers]);

  useEffect(() => {
    getAllTenants();
  }, [getAllTenants]);

  useEffect(() => {
    fetchGroupList();
  }, [fetchGroupList]);

  if (loading || isLoading || isSubmitting) {
    return <Loader />;
  }

  const Option = (props) => {
    const { label, caption } = props.data;

    return (
      <components.Option {...props}>
        <div className="new-group-users-table-label">{label}</div>
        <div className="group-caption">{caption}</div>
      </components.Option>
    );
  };

  return (
    <>
      {!loading && !secondaryLoading 
        // && card === 1 
        && (
          <div className="users-main">
            <div>
              <p className="new-group-users-table-add-title">Add group</p>
              <h4 className="new-group-users-table-add-subtitle">Select group</h4>
              <div className="group-select">
                <Select
                  id="select"
                  options={systemGroups}
                  components={{ Option }}
                  className="group-multiselect"
                  placeholder="Select group"
                  value={selectedSystemGroup}
                  onChange={handleSelect}
                  isSearchable={true}
                  closeMenuOnSelect={true}
                  styles={colourStyles}
                />
              </div>
              <h4 className="new-group-users-table-add-subtitle">
                Select users for the group
              </h4>

              <div className="input-container">
                <input
                  id="users"
                  name="users"
                  placeholder="Search users..."
                  value={filterValue}
                  className="group-input-edit multidelect"
                  onChange={(e) => setFilterValue(e.target.value)}
                />

                <span>OR</span>

                {!tenants.length && !secondaryLoading ? (
                  <CustomizedTooltipDisabled
                    position={'top'}
                    text1={'Will be active after first sync with'}
                    text2={'Azure Active Directory or'}
                    text3={'Google Workspace'}
                    button={
                      <button
                        className="group-button-secondary"
                        disabled={!tenants.length}
                        onClick={() => setSyncModal(true)}
                      >
                        Sync with integration
                      </button>
                    }
                  />
                ) : (
                  <button
                    className="group-button-secondary"
                    disabled={!tenants.length}
                    onClick={() => setSyncModal(true)}
                  >
                    Sync with integration
                  </button>
                )}

                {/* TODO: decide how to be with CSV tenants when users to group are added */}
                {/* <span>OR</span>

              <button
                className="group-button-secondary"
                disabled={selectedRows.length}
                onClick={() => setCard(2)}
              >
                Upload CSV file
              </button> */}
              </div>

              <button
                className="group-button group-button-simple"
                onClick={() => submitHandler(selectedRows)}
                disabled={!selectedRows.length || !selectedSystemGroup}
                type="submit"
              >
                Add users to the group
              </button>
              {!!selectedRows.length && (
                <>
                  <div className="group-selected-bar">
                    <button
                      className={
                        selectedUsersShown
                          ? 'email-button-active'
                          : 'email-button'
                      }
                      onClick={() => setSelectedUsersShown((prev) => !prev)}
                    >
                      {selectedRows.length < 2
                        ? `${selectedRows.length} user selected`
                        : `${selectedRows.length} users selected`}
                    </button>
                    <button
                      className="secondary-button"
                      onClick={() => setSelectedRows([])}
                    >
                      Clear all
                    </button>
                  </div>

                  {!!selectedUsersShown && (
                    <div className="emails-container">
                      {selectedRows.map((selectedId, i) => (
                        <div className="email-container" key={i}>
                          {allUsers.find((u) => u.id === selectedId)?.email}
                          <span className="email-container-img">
                            <img
                              onClick={() =>
                                setSelectedRows((prev) =>
                                  prev.filter((id) => id !== selectedId)
                                )
                              }
                              src={cross}
                              alt="delete"
                            ></img>
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}

              <div className="audit table">
                <table className="new-group-users-table-table">
                  <thead className="table-th">
                    <tr className="new-group-users-table-not-clickable new-group-users-table-tr">
                      <th></th>
                      <th>User ID</th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('email')}
                          className={getClassNamesFor('email', sortConfig)}
                        >
                          Email
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('firstName')}
                          className={getClassNamesFor('firstName', sortConfig)}
                        >
                          First name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('secondName')}
                          className={getClassNamesFor('secondName', sortConfig)}
                        >
                          Last name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('tenantName')}
                          className={getClassNamesFor('tenantName', sortConfig)}
                        >
                          Tenant
                        </button>
                      </th>
                      <th>Sync method</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {items.map((u, i) => {
                      if (i < page * itemsOnPage) {
                        return (<tr
                          key={u.id}
                          onClick={() =>
                            setSelectedRows((prevState) =>
                              prevState.includes(u.id)
                                ? prevState.filter((id) => id !== u.id)
                                : [...prevState, u.id]
                            )
                          }
                          className={'new-group-users-table-clickable-row new-group-users-table-tr '.concat(
                            selectedRows.includes(u.id) ? 'group-selected' : ''
                          )}
                        >
                          <td>
                            <div className="user-checkbox-action">
                              <label className="checkbox-label">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  checked={selectedRows.includes(u.id)}
                                  onChange={() =>
                                    setSelectedRows((prevState) =>
                                      prevState.includes(u.id)
                                        ? prevState.filter((id) => id !== u.id)
                                        : [...prevState, u.id]
                                    )
                                  }
                                />
                                <span className="checkbox-span"></span>
                              </label>
                            </div>
                          </td>
                          <td className="group-td-email">{u.id}</td>
                          <td className="group-td-email">{u.email}</td>
                          <td>{u?.firstName}</td>
                          <td>{u?.secondName}</td>
                          <td className="group-td-email">{u.tenantName}</td>
                          <td className="list-td-email">
                            <span>
                              {u.syncType && (
                                <img
                                  src={
                                    u.syncType === 'azure'
                                      ? azure
                                      : u.syncType === 'google'
                                        ? google
                                        : u.syncType === 'csv'
                                          ? csv
                                          : ''
                                  }
                                  alt="Logo"
                                  className="list-td-email-icon"
                                ></img>
                              )}
                              {u.syncType}
                            </span>
                          </td>
                        </tr>)
                      }
                      return null
                    })}
                  </tbody>
                </table>
                {page * itemsOnPage < items.length && <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <button
                    className='list-button'
                    onClick={() => {
                      setPage((prev) => prev + 1)
                    }}
                    disabled={loading}
                    type="submit"
                  >
                    Download more
                  </button>
                </div>
                }
              </div>
            </div>
          </div>
        )}

      {/*
        Temporary remove csv upload
       {!loading && card === 2 && (
        <div className="csv-card">
          <p className="new-group-users-table-add-title">Add group</p>
          <h4 className="new-group-users-table-add-subtitle">Select group</h4>
          <div className="group-select">
            <Select
              id="select"
              options={systemGroups}
              components={{ Option }}
              className="group-multiselect"
              placeholder="Select group"
              value={selectedSystemGroup}
              onChange={handleSelect}
              isSearchable={true}
              closeMenuOnSelect={true}
              styles={colourStyles}
            />
          </div>
          <div className="csv-card-title">
            <p className="details-help">
              <span>Need help with a CSV file? </span>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.phishfirewall.com/onboarding-instructions/step-two/#toggle-id-3"
              >
                View our setup instructions.
              </a>
            </p>
            <p className="details-help">
              <a
                target="_blank"
                rel="noreferrer"
                href="/csv_template.csv"
                download
              >
                Download CSV template
              </a>
            </p>
            <h2>CSV file uploading</h2>
          </div>
          <form onSubmit={() => (this.refs.file.value = '')}>
            <div className="new-users-group-input">
              <input
                type="file"
                accept=".csv"
                onChange={handleOnChange}
                ref={ref}
              />
            </div>
            <br />
            <button
              onClick={(e) => {
                handleOnSubmit(e);
              }}
              className="button-submit"
              disabled={
                !fileName?.endsWith('.csv') ||
                file?.size > 10485760 ||
                !selectedSystemGroup
              }
            >
              <img src={submit} alt="submit"></img>
              Submit
            </button>
          </form>
        </div>
      )} */}

      {!!tenants.length && syncModal &&  <SyncModal
        groupName={selectedSystemGroup?.label}
        setSyncModal={setSyncModal}
        syncModal={syncModal}
        setIsLoading={setIsLoading}
        navigaveToGroup={true}
      />}
    </>
  );
};
