import React, { useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import cn from 'classnames';
import { useHttp } from '../../hooks/http.hook';
import { AllUsersActivity } from './AllUsersActivity/AllUsersActivity';
import { Loader } from '../Loader/Loader';
import { AnalyticsGeneralContext } from '../../context/AnalyticsGeneralContext';

export const MailingAndActionsList = ({
  selectedTenant, selectedUser,
  startDate, setStartDate,
  endDate, setEndDate, params,
  selectedDateRange, setSelectedDateRange,
  department, location, groupId
}) => {
  const { secondaryLoading } = useHttp();
  const {
    startDateParam, endDateParam, emailTypeParam,
    actionTypeParam, removeQueryParams, dateRangeParam,
    departmentParam, locationParam, groupIdParam
  } = useOutletContext();
  const { generalLoading } = useContext(AnalyticsGeneralContext);

  return (
    <div
      className={cn({ 'history-chart-loading': secondaryLoading || generalLoading })}
    >
      {startDate && endDate
        ? <AllUsersActivity
            tenantId={selectedTenant?.value}
            userId={selectedUser?.value}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectedDateRange={selectedDateRange}
            startDateParam={startDateParam}
            endDateParam={endDateParam}
            emailTypeParam={emailTypeParam}
            actionTypeParam={actionTypeParam}
            removeQueryParams={removeQueryParams}
            dateRangeParam={dateRangeParam}
            setSelectedDateRange={setSelectedDateRange}
            params={params}
            departmentParam={departmentParam}
            locationParam={locationParam}
            groupIdParam={groupIdParam}
            department={department}
            location={location}
            groupId={groupId}
          />
        : <Loader />
      }
    </div>
  )
};
