import React, { useState, useCallback, useEffect, useContext, useRef } from 'react';
import cn from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';

import { AuthContext } from '../../../context/AuthContext';
import { useCrypto } from '../../../hooks/crypto.hook';
import { useHttp } from '../../../hooks/http.hook';
import { reportScheduleSendingFrequencyOptions } from '../../../config/config';
import { Loader } from '../../Loader/Loader';
import { DeleteScheduleModal } from '../DeleteScheduleModal/DeleteScheduleModal';
import { dateRangeOptions } from '../../../config/config';
import './ReportSchedule.css';

export const ReportSchedule = () => {
  const { request, loading, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token } = useContext(AuthContext);
  const { decryptData } = useCrypto();
  const emailRefs = useRef([]);
  const tenantRefs = useRef([]);
  const groupRefs = useRef([]);
  const departmentRefs = useRef([]);
  const locationRefs = useRef([]);

  const [reportSchedules, setReportSchedules] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editSchedule, setEditSchedule] = useState({
    userId: null,
    email: '',
    companyId: '',
    tenantId: '',
    groupId: '',
    department: '',
    location: '',
    dateRange: null,
    summaryPageSelect: null,
    riskPageSelect: null,
    phishingPageSelect: null,
    educationPageSelect: null,
    sendingFrequencyId: null
  });

  const fetchReportSchedules = useCallback(async () => {
    try {
      if (token) {
        const response = await request('/back_office/api/analytics/report_schedules', 'GET', null, {
          Authorization: `Bearer ${token}`
        });

        const decryptedSchedules = decryptData(response);
        setReportSchedules(decryptedSchedules);
      }
    } catch (error) {
      console.error('Error fetching report schedules:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    fetchReportSchedules();
  }, [fetchReportSchedules]);

  const openDeleteModal = () => {
    setDeleteModal(true);
  }

  const closeDeleteScheduleModal = () => {
    setDeleteModal(false);
    setEditSchedule({
      userId: null,
      email: '',
      companyId: '',
      tenantId: '',
      groupId: '',
      department: '',
      location: '',
      dateRange: null,
      summaryPageSelect: null,
      riskPageSelect: null,
      phishingPageSelect: null,
      educationPageSelect: null,
      sendingFrequencyId: null
    });
  };

  const getFrequencyLabel = (frequencyId) => {
    const frequency = reportScheduleSendingFrequencyOptions.find(opt => opt.value === frequencyId);
    return frequency ? frequency.label : 'Unknown';
  };

  const isTextOverflowing = (element) => {
    return element.scrollWidth > element.clientWidth;
  };

  const updateTitles = () => {
    emailRefs.current.forEach((element) => {
      if (element && isTextOverflowing(element)) {
        element.setAttribute('title', element.textContent);
      } else {
        element?.removeAttribute('title');
      }
    });

    tenantRefs.current.forEach((element) => {
      if (element && isTextOverflowing(element)) {
        element.setAttribute('title', element.textContent);
      } else {
        element?.removeAttribute('title');
      }
    });

    groupRefs.current.forEach((element) => {
      if (element && isTextOverflowing(element)) {
        element.setAttribute('title', element.textContent);
      } else {
        element?.removeAttribute('title');
      }
    });

    departmentRefs.current.forEach((element) => {
      if (element && isTextOverflowing(element)) {
        element.setAttribute('title', element.textContent);
      } else {
        element?.removeAttribute('title');
      }
    });

    locationRefs.current.forEach((element) => {
      if (element && isTextOverflowing(element)) {
        element.setAttribute('title', element.textContent);
      } else {
        element?.removeAttribute('title');
      }
    });
  };

  useEffect(() => {
    updateTitles();

    window.addEventListener('resize', updateTitles);

    return () => {
      window.removeEventListener('resize', updateTitles);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportSchedules]);

  return (
    <>
      <div className={cn('user-analytics-card', {
        'user-analytics-card--loading': secondaryLoading,
      })}>
        <div className='report-schedules-container'>
          <h3 className={cn({'user-analytics-card--found': reportSchedules.length !== 0 })}>Report Schedule</h3>
          
          {loading ? (
            <Loader />
          ) : (
            <div>
              {reportSchedules.length === 0 ? (
                <div className='no-report-schedules-message'>
                  <p>No report schedules found</p>
                </div>
              ) : (
                <table className='report-schedules-table'>
                  <thead className='table-th-report-schedules'>
                    <tr className='tr-report-schedules tr-grid-report-schedules'>
                      <th>Email</th>
                      <th>Tenant</th>
                      <th>Group</th>
                      <th>Department</th>
                      <th>Location</th>
                      <th>Pages</th>
                      <th>Date Range</th>
                      <th>Frequency</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className='table-body table-body-report-schedules'>
                    {reportSchedules.map((schedule, index) => (
                      <tr key={schedule.id} className='tr-report-schedules tr-grid-report-schedules'>
                        <td className='list-td-report-schedules'>
                          <div ref={(el) => (emailRefs.current[index] = el)} className='list-td-report-schedules-div'>
                            {schedule?.user?.email} <br />
                            <span className='list-td-report-schedules-div-span'>{` ${schedule?.user?.firstName}`} {schedule?.user?.secondName}</span>
                          </div>
                        </td>
                        <td 
                          className='list-td-report-schedules'
                          ref={(el) => (tenantRefs.current[index] = el)}
                        >
                          {schedule?.tenant?.name}
                        </td>
                        <td className='list-td-report-schedules'>
                          <div ref={(el) => (groupRefs.current[index] = el)} className='list-td-report-schedules-div'>
                            {schedule?.groupName}
                          </div>
                        </td>
                        <td className='list-td-report-schedules'>
                          <div ref={(el) => (departmentRefs.current[index] = el)} className='list-td-report-schedules-div'>
                            {schedule?.department}
                          </div>
                        </td>
                        <td className='list-td-report-schedules'> 
                          <div ref={(el) => (locationRefs.current[index] = el)} className='list-td-report-schedules-div'>
                            {schedule?.location}
                          </div>
                        </td>
                        <td 
                          className='list-td-report-schedules'
                        >
                          {schedule?.summaryPageSelect ? 'Summary ' : ''}
                          {schedule?.riskPageSelect ? 'Risk ' : ''}
                          {schedule?.phishingPageSelect ? 'Phishing ' : ''}
                          {schedule?.educationPageSelect ? 'Education' : ''}
                        </td>
                        <td className='list-td-report-schedules'>{dateRangeOptions.find(opt => opt.value === schedule?.dateRange)?.label}</td>
                        <td className='list-td-report-schedules'>{getFrequencyLabel(schedule.sendingFrequencyId)}</td>
                        <td className='list-td-report-schedules'>
                            <button
                              className="all-tenant-button delete-tenant"
                              onClick={() => {
                                setEditSchedule({
                                  userId: schedule?.userId,
                                  email: schedule?.user?.email,
                                  companyId: schedule?.companyId,
                                  tenantId: schedule?.tenantId,
                                  groupId: schedule?.groupId,
                                  department: schedule?.department,
                                  location: schedule?.location,
                                  dateRange: schedule?.dateRange,
                                  summaryPageSelect: schedule?.summaryPageSelect,
                                  riskPageSelect: schedule?.riskPageSelect,
                                  phishingPageSelect: schedule?.phishingPageSelect,
                                  educationPageSelect: schedule?.educationPageSelect,
                                  sendingFrequencyId: schedule?.sendingFrequencyId
                                })
                                openDeleteModal()
                              }}
                            >
                              Delete
                            </button>
                          </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </div>
      </div>

      <DeleteScheduleModal
        isOpen={deleteModal}
        onRequestClose={closeDeleteScheduleModal}
        editSchedule={editSchedule}
        callback={fetchReportSchedules}
        requestWithSecondaryLoading={requestWithSecondaryLoading}
        secondaryLoading={secondaryLoading}
      />
    </>
  );
};
