import React, { useState, useCallback, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from 'react-scroll-to-top';
import Modal from 'react-modal';
import cn from 'classnames';
import { useRoutes } from './routes';
import { useAuth } from './hooks/auth.hook';
import { AuthContext } from './context/AuthContext';
import { Loader } from './components/Loader/Loader';
import { useHttp } from './hooks/http.hook';
import { ReactComponent as MySVG } from '../src/images/icons/Scroll up.svg';
import { useCrypto } from './hooks/crypto.hook';
import './App.css';

Modal.setAppElement('#root');

export const App = () => {
  const { request, loading } = useHttp();
  const { token, login, logout, userId, ready } = useAuth();
  const { decryptData } = useCrypto();

  const showToastMessage = (error, message, warning, info) => {
    try {
      toast(error, {
        type: 'error',
        position: 'top-right',
        autoClose: 10000,
        className: 'toast-message-error'
      });
      toast(message, {
        type: 'success',
        position: 'bottom-right',
        autoClose: 10000,
        className: 'toast-message-success'
      });
      toast(warning, {
        type: 'warning',
        position: 'top-center',
        autoClose: 20000,
        className: 'toast-message-warning'
      });
      toast(info, {
        type: 'info',
        position: 'bottom-right',
        autoClose: 20000,
        className: 'toast-message-info'
      });

      return toast
    } catch (error) { }
  };

  const isAuthenticated = !!token
  const [user, setUser] = useState({});
  const [planPaused, setPlanPaused] = useState(false);
  const [dateExpired, setDateExpired] = useState(false);
  const [phishReports, setPhishReports] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [allGroups, setGroups] = useState([]);
  const [systemGroups, setSystemGroups] = useState([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);

  let count = 0;

  const fetchPhishReports = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/phish_reports', 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptReports = decryptData(fetched);
        setPhishReports(decryptReports);
        count++;

        if (decryptReports.new && count === 1) {
          showToastMessage(fetched.error, fetched.message, null, 'Some users from your company have created "Phishing Reports", you can find and review them in the tab on the left')
        }

        return decryptReports;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchUser = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/user', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        if (!fetched) {
          logout();
        }
        const decryptUser = decryptData(fetched);
        setUser(decryptUser);

        const today = new Date();
        const expiredData = new Date(decryptUser?.paymentExpires);

        if (today > expiredData) {
          setDateExpired(true);
        }

        if (decryptUser?.stripePayment?.active && !dateExpired) {
          setPlanPaused(decryptUser?.stripePayment?.subscriptionStatus === 'paused');
        }

        return fetched
      }
    } catch (error) {
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const fetchAllUsers = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(`/back_office/api/groups/all_users`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptUsers = decryptData(fetched);
        setAllUsers(decryptUsers);

        return decryptUsers;
      }

    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const fetchAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          `/back_office/api/user/get_all_tenants`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const decryptUsers = decryptData(fetched);

        setTenants(decryptUsers);

        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const fetchAllGroupsList = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          `/back_office/api/groups/all_groups`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const decryptGroups = decryptData(fetched);

        setGroups(decryptGroups);

        return decryptGroups;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const fetchSystemGroupsList = useCallback(async () => {
    try {
      if (token) {
        setIsLoadingGroups(true);
        const fetched = await request(
          `/back_office/api/groups/groups`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const decryptGroups = decryptData(fetched);

        setSystemGroups(decryptGroups);
        setIsLoadingGroups(false);
        return decryptGroups;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    fetchAllTenants()
  }, [fetchAllTenants]);

  useEffect(() => {
    if ((user.paymentPlanId === 3 || user.paymentPlanId === 4) && !dateExpired) {
      fetchPhishReports();
    }
  }, [dateExpired, fetchPhishReports, user.paymentPlanId]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  useEffect(() => {
    if ((user.paymentPlanId === 4) && !dateExpired) {
      fetchAllGroupsList();
    }
  }, [fetchAllGroupsList, user.paymentPlanId, dateExpired]);

  useEffect(() => {
    if ((user.paymentPlanId === 4) && !dateExpired) {
      fetchSystemGroupsList();
    }
  }, [fetchSystemGroupsList, user.paymentPlanId, dateExpired]);

  const routes = useRoutes(isAuthenticated, user, planPaused, dateExpired, allUsers, tenants);

  if (!ready || !user) {
    return <Loader />
  }

  return (
    <div className={cn('container', { 'loading': loading })}>
      <Router>
        <AuthContext.Provider value={{
          token, login, logout, userId, isAuthenticated, user, showToastMessage,
          fetchUser, planPaused, dateExpired, phishReports, fetchPhishReports, allUsers,
          fetchAllUsers, fetchAllTenants, allGroups, systemGroups, fetchSystemGroupsList,
          isLoadingGroups
        }}>
          {routes}
          <ToastContainer />
          <ScrollToTop top={400} smooth component={<MySVG />} />
        </AuthContext.Provider>
      </Router>
    </div>
  )
}
