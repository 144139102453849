import React from 'react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useTitle } from '../../hooks/title.hook';
import { useHttp } from '../../hooks/http.hook';
import { CustomizedTooltipDisabled } from '../Tooltips/Tooltip';
import { AnalyticsGeneralProvider } from '../../context/AnalyticsGeneralProvider';
import './OldUserAnalytics.css';
import './UserAnalytics.css';

export const UserAnalyticsMain = () => {
  useTitle('PhishFirewall | Analytics');

  const { loading } = useHttp();

  return (
    <AnalyticsGeneralProvider>
      <> {!loading &&
          <div className='analytics-main'>
            <h2 className='analytics-main-title'>
              User analytics
            </h2>

            <nav className='analytics-nav'>
              <NavLink
                to={{
                  pathname: '/back_office/activity/user_analytics/executive_summary',
                }}
                className={({ isActive }) => cn('analytics-item',
                  { 'analytics-item--active': isActive })}
              >
                Executive Summary
              </NavLink>

              <div>
                {false &&
                  <CustomizedTooltipDisabled
                    position={'top'}
                    text1={'This functionality'}
                    text2={'is not available yet'}
                  />
                }

                <NavLink
                  to='/back_office/activity/user_analytics/risk'
                  className={({ isActive }) => cn('analytics-item', {
                    'analytics-item--active': isActive,
                    // 'item--disabled': true
                  })}
                >
                  Risk
                </NavLink>
              </div>

              <div>
                {false &&
                  <CustomizedTooltipDisabled
                    position={'top'}
                    text1={'This functionality'}
                    text2={'is not available yet'}
                  />
                }

                <NavLink
                  to='/back_office/activity/user_analytics/phishing'
                  className={({ isActive }) => cn('analytics-item', {
                    'analytics-item--active': isActive,
                    // 'item--disabled': true
                  })}
                >
                  Phishing
                </NavLink>
              </div>

              <div>
                {false &&
                  <CustomizedTooltipDisabled
                    position={'top'}
                    text1={'This functionality'}
                    text2={'is not available yet'}
                  />
                }

                <NavLink
                  to='/back_office/activity/user_analytics/education'
                  className={({ isActive }) => cn('analytics-item', {
                    'analytics-item--active': isActive,
                    // 'item--disabled': true
                  })}
                  // onClick={(e) => e.preventDefault()}
                >
                  Education
                </NavLink>
              </div>

              <NavLink
                to='/back_office/activity/user_analytics/user_report_card'
                className={({ isActive }) => cn('analytics-item',
                  { 'analytics-item--active': isActive })}
              >
                User Report Card
              </NavLink>

              <NavLink
                to='/back_office/activity/user_analytics/report_schedule'
                className={({ isActive }) => cn('analytics-item', {
                  'analytics-item--active': isActive })}
              >
                Report Schedule
              </NavLink>
            </nav>
            <Outlet />
          </div>
        }
      </>
    </AnalyticsGeneralProvider>
  )
};
