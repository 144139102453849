import React, { useContext } from 'react';
import Modal from 'react-modal';
import { customModalStyles } from '../../../config/config';
import { useCrypto } from '../../../hooks/crypto.hook';
import { AuthContext } from '../../../context/AuthContext';
import './DeleteScheduleModal.css';

export const DeleteScheduleModal = ({
  isOpen,
  onRequestClose,
  editSchedule,
  callback,
  requestWithSecondaryLoading,
  secondaryLoading
}) => {
  const { encryptData } = useCrypto();
  const { token, showToastMessage } = useContext(AuthContext);

  const updateSchedule = async (
    userId, email, companyId, tenantId, groupId ,department, location, dateRange,
    summaryPageSelect, riskPageSelect, phishingPageSelect, educationPageSelect, sendingFrequencyId) => {
    try {
      onRequestClose();
      const data = encryptData({
        userId,
        email,
        companyId,
        tenantId,
        groupId,
        department,
        location,
        dateRange,
        summaryPageSelect,
        riskPageSelect,
        phishingPageSelect,
        educationPageSelect,
        sendingFrequencyId
      });
      const res = await requestWithSecondaryLoading('/back_office/api/analytics/delete_report_schedule', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })

      showToastMessage(res.error, res.message);

      if (callback) callback();
    } catch (error) { }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Delete Modal"
      style={customModalStyles}
    >
      <div className='delete-report-schedule-modal'>
        <div className='delete-report-schedule-content'>
          <span className="card-title">Delete report schedule</span>
          <span className='card-subtitle'>
            Are you sure you want to delete this report schedule? <strong>(This action cannot be undone)</strong>
          </span>
          <div className="delete-modal-buttons">
            <button onClick={onRequestClose} className="modal-button-cancel">Cancel</button>
            <button
              onClick={() => updateSchedule(
                editSchedule.userId, editSchedule.email, editSchedule.companyId, editSchedule.tenantId,
                editSchedule.groupId, editSchedule.department, editSchedule.location, editSchedule.dateRange,
                editSchedule.summaryPageSelect, editSchedule.riskPageSelect, editSchedule.phishingPageSelect, 
                editSchedule.educationPageSelect, editSchedule.sendingFrequencyId
              )}
              className="modal-button-delete"
              disabled={secondaryLoading}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
