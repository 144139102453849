import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { Outlet } from "react-router-dom";
import { useHttp } from '../../hooks/http.hook';
import { useTitle } from '../../hooks/title.hook';
import { AnalyticsGeneralProvider } from '../../context/AnalyticsGeneralProvider';
import { dateRangeOptions } from '../../config/config';
import './MailingAndActionsMain.css';

export const MailingAndActionsMain = () => {
  useTitle("PhishFirewall | Mailing History");
  const { loading } = useHttp();
  const [newVersion, setNewVersion] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [dateRangeOptionsList, setDateRangeOptionsList] = useState(dateRangeOptions);

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const startDateParam = params.get('start_date');
  const endDateParam = params.get('end_date');
  const emailTypeParam = params.get('email_type') ? params.get('email_type')?.split(',') : '';
  const actionTypeParam = params.get('action_type') ? params.get('action_type')?.split(',') : '';
  const tenantId = params.get('tenant_id');
  const userId = params.get('user_id');
  const dateRangeParam = params.get('date_range');
  const department = params.get('department');
  const locationParam = params.get('location');
  const groupIdParam = params.get('groupId');

  const path = window.location.pathname;

  const removeQueryParams = () => {
    if (params.size) {
      navigate(path, { replace: true });
    }
  };

  useEffect(() => {
    if (path === '/back_office/activity/mailing_and_actions') {
      setNewVersion(true);
      navigate('/back_office/activity/mailing_and_actions/chart_list');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVersion, path]);

  useEffect(() => {
    if (!params.size)
      navigate(newVersion
        ? '/back_office/activity/mailing_and_actions/chart_list'
        : path === '/back_office/activity/mailing_and_actions/chart_list'
          ? '/back_office/activity/mailing_and_actions/mailing_chart'
          : path
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVersion]);

  return (
    <> {!loading &&
        <AnalyticsGeneralProvider>
          <div className="history-main">
            <div className="history-main--version">
              <h2 className="history-main-title">
                Mailing & Actions
              </h2>
              {!params.size &&
                <button
                  onClick={() => setNewVersion(!newVersion)}
                >
                  {`Go to ${newVersion ? 'old' : 'new'} version`}
                </button>
              }
            </div>

            {!newVersion &&
              <nav className="history-nav">
                <NavLink
                  to="/back_office/activity/mailing_and_actions/mailing_chart"
                  className={({ isActive }) => cn('history-item',
                    { 'history-item--active': isActive })}
                >
                  Mailing chart
                </NavLink>
                <NavLink
                  to="/back_office/activity/mailing_and_actions/actions_chart"
                  className={({ isActive }) => cn('actions-item',
                    { 'actions-item--active': isActive })}
                >
                  Action chart
                </NavLink>
                <NavLink
                  to="/back_office/activity/mailing_and_actions/mailing_list"
                  className={({ isActive }) => cn('history-item',
                    { 'history-item--active': isActive })}
                >
                  Mailing list
                </NavLink>
                <NavLink
                  to="/back_office/activity/mailing_and_actions/actions_list"
                  className={({ isActive }) => cn('actions-item',
                    { 'actions-item--active': isActive })}
                >
                  Actions list
                </NavLink>
              </nav>
            }

            <Outlet context={
              { startDateParam, endDateParam, emailTypeParam,
                actionTypeParam, tenantId, userId, removeQueryParams, params,
                dateRangeOptionsList, setDateRangeOptionsList, dateRangeParam,
                department, locationParam, groupIdParam
              }
            } />
          </div>
        </AnalyticsGeneralProvider>
      }
    </>
  )
};
