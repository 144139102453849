import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import './GroupsMapping.css';
import { NavLink } from 'react-router-dom';
import azure from '../../images/icons/Azure.svg';
import google from '../../images/icons/Google (colors).svg';
import Select, { components } from 'react-select';
import colourStyles from '../../styles/colour-style';
import { AuthContext } from '../../context/AuthContext';
import { CustomizedTooltipDisabled } from '../../components/Tooltips/Tooltip';
import { syncOptions } from '../../config/config';
// import { newMappingCard } from "../../components/Groups/Mapping/NewMappingCard"
import { useLocation } from 'react-router-dom';
import { SyncModal } from '../../components/Modal/SyncModal.js';
import { GroupsSelect } from '../../components/GroupsSelect/GroupsSelect.js';

export const GroupsMapping = () => {
  const { loading, request, requestWithSecondaryLoading } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const [tenants, setTenants] = useState([]);
  const [newMappingCard, setNewMappingCard] = useState(false);
  const [selectedSyncType, setSyncType] = useState(syncOptions[0]);
  const [selectedTenant, setSelectedTenant] = useState();
  const [selectedSyncGroups, setSyncGroups] = useState([]);
  const [systemGroupsOptionList, setSystemGrousOptionList] = useState([]);
  const [systemGroupsLoading, setSystemGroupsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(0);
  const [syncModal, setSyncModal] = useState(false);
  const [editedTenant, setEditedTenant] = useState();
  const [editedGroup, setEditedGroup] = useState();
  const [syncTypes, setSyncTypes] = useState(syncOptions);
  const [groupMapping, setGroupMapping] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [remoteGroups, setRemoteGroups] = useState([]);
  const [newMapLoading, setNewMapLoading] = useState(false);
  const [mappingListLoading, setMappingListLoading] = useState(false);
  const { showToastMessage, fetchSystemGroupsList } = useContext(AuthContext);
  const location = useLocation();
  const [locationState, setLocationState] = useState(location.state || null);


  const syncOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`syncTypes-label-${value.toString().split(' ').join('-')} groups-map-tenant-label`}>
          {label}
        </div>
      </components.Option>
    );
  };

  const azureTenants = tenants?.filter((t) => t.type === 'azure');
  const googleTenants = tenants?.filter((t) => t.type === 'google');
  const azureTenantsArray = azureTenants?.map((e) => {
    return {
      label: e.name,
      id: e.id,
      value: e.name.split(' ').join('_'),
    };
  });

  const googleTenantsArray = googleTenants?.map((e) => {
    return {
      label: e.name,
      id: e.id,
      value: e.name.split(' ').join('_'),
    };
  });

  const tenantsOption = (props) => {
    const { label } = props.data;

    return (
      <components.Option {...props}>
        <div className={`groups-map-tenant-label`}>{label}</div>
      </components.Option>
    );
  };

  const fetchRemoteGroups = useCallback(async () => {
    try {
      const tenantId = selectedTenant?.id || locationState?.tenantId;
      if (token && tenantId) {
        setNewMapLoading(true);
        const fetched = await requestWithSecondaryLoading(`/back_office/api/groups/sync_group_list?tenantId=${tenantId}`, 'GET', null, {
          Authorization: `Bearer ${token}`,
        })
        const groups = decryptData(fetched)
        setRemoteGroups(groups)
        setNewMapLoading(false);
      }
    } catch (error) {
      setNewMapLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, requestWithSecondaryLoading, selectedTenant?.id, selectedSyncType?.value]);

  const fetchAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/user/get_all_tenants',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptUsers = decryptData(fetched);
        setTenants(decryptUsers.filter((t) => t.type !== 'csv'));
        const syncTypesOptions = decryptUsers.map((t) => t.type);
        const syncOptions = syncTypes.filter((t) =>
          syncTypesOptions.includes(t.value)
        );

        setSyncTypes(syncOptions);
        setSyncType(syncOptions[0]);

        const firstTenant = decryptUsers?.filter(
          (t) => t.type === syncOptions[0].value
        )[0];

        handleTenantChange({
          value: firstTenant?.name.split(' ').join('-'),
          label: firstTenant?.name,
          id: firstTenant?.id,
        });

        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const getGroupMap = useCallback(async () => {
    try {
      if (token) {
        setMappingListLoading(true);
        const fetched = await requestWithSecondaryLoading(
          '/back_office/api/groups/groups_map/all',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptUsers = decryptData(fetched);
        setGroupMapping(decryptUsers);
        setMappingListLoading(false);

        return decryptUsers;
      }
    } catch (error) {
      setMappingListLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, requestWithSecondaryLoading]);

  const changeGroupMapping = async ({
    destGroup,
    selectedSyncGroups,
    tenantId,
  }) => {
    const selectedData = {
      selectedSyncGroups,
      destGroupName: destGroup,
      tenantId,
    };

    if (token) {
      const data = encryptData(selectedData);
      setNewMapLoading(true);
      const response = await requestWithSecondaryLoading(
        `/back_office/api/groups/create_groups_map`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      setSyncGroups([]);
      setSelectedTenant();
      setEditedTenant();
      setSelectedOptions([]);
      await fetchAllTenants();
      await getGroupMap();
      fetchSystemGroupsList();
      setNewMapLoading(false);
      setLocationState(null);
      showToastMessage(response.error, response.message);
    }
  };

  const deleteMap = async (destGroup, tenantId, tenantType) => {
    if (token) {
      const selectedData = {
        destGroupName: destGroup,
        tenantId,
        syncType: tenantType,
      };

      const data = encryptData(selectedData);
      setMappingListLoading(true);
      const response = await requestWithSecondaryLoading(
        `/back_office/api/groups/delete_group_map`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      await getGroupMap();
      fetchSystemGroupsList()
      setMappingListLoading(false);
      showToastMessage(response.error, response.message);
    }
  };

  const handleSyncGroupsChange = (data) => {
    setSyncGroups(data);

  };

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setEditedTenant();
    setSyncGroups([]);
  };

  const handleSyncTypeChange = (data) => {
    setSyncType(data);
    setSyncGroups([]);
    handleTenantChange(
      data.value === 'azure' ? azureTenantsArray[0] : googleTenantsArray[0]
    );
  };

  const handleChangeEditedTenant = (tenantId) => {
    const tenant = tenants.find((t) => t.id === tenantId);
    setEditedTenant(tenant);
  }

  const fetchGroupList = useCallback(async () => {
    try {
      if (token && selectedTenant?.value) {
        setSystemGroupsLoading(true);

        const fetched = await requestWithSecondaryLoading(
          `/back_office/api/groups/group_options_list?tenantId=${selectedTenant?.id}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptGroups = decryptData(fetched);
        const options = decryptGroups.map((e) => {
          return {
            value: e.id,
            label: e.label,
          };
        }
        );
        setSystemGrousOptionList(options);
        setSystemGroupsLoading(false);

        return decryptGroups;
      }
    } catch (error) {
      setSystemGroupsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, requestWithSecondaryLoading, selectedTenant?.value]);

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const Option = (props) => {
    const { label } = props.data;

    return (
      <components.Option {...props}>
        <div className="groups-map-table-label">{label}</div>
      </components.Option>
    );
  };

  useEffect(() => {
    fetchGroupList();
  }, [fetchGroupList]);

  useEffect(() => {
    fetchAllTenants();
  }, [fetchAllTenants]);

  useEffect(() => {
    getGroupMap();
  }, [getGroupMap]);

  useEffect(() => {
    fetchRemoteGroups();
  }, [fetchRemoteGroups]);

  useEffect(() => {
    if (locationState?.tenantId) {
      setNewMappingCard(true);
      const tenant = tenants.find((t) => t.id === locationState.tenantId);
      const syncType = syncTypes.find((t) => t.value === tenant?.type);
      setSyncType(syncType);
      setSelectedTenant({
        label: tenant?.name,
        value: tenant?.name.split(' ').join('-'),
        id: tenant?.id,
      });

      if (locationState?.syncGroupId) {
        setSyncGroups([{
          value: locationState.syncGroupId,
          label: remoteGroups.find(e => e.id === locationState.syncGroupId)?.name,
        }]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState, remoteGroups]);

  if (loading || isLoading) {
    return <Loader />;
  }

  return (
    <>
      {!loading && (
        <div>
          {/* {newMappingCard && (
            <NewMappingCard
              newMappingCard={newMappingCard}
              setNewMappingCard={setNewMappingCard}
              changeGroupMapping={changeGroupMapping}
            />
          )} */}

          {newMappingCard && (
            <div className="users-main">
              <div>
                <h3>Add new mapping</h3>
                {(!!tenants.length && !loading) ? (
                  <div className="new-mapping-input">
                    <div
                      className={`new-mapping-select groups-map-select-sync`}
                    >
                      <label className="new-mapping-select-label">
                        Sync method
                        <Select
                          id="select"
                          options={syncTypes}
                          components={{ syncOption }}
                          placeholder="Select sync type"
                          value={selectedSyncType}
                          onChange={handleSyncTypeChange}
                          isSearchable={true}
                          styles={colourStyles}
                        />
                      </label>
                    </div>
                    <div className="new-mapping-select groups-map-select-tenant">
                      <label className="new-mapping-select-label">
                        Tenant
                        <Select
                          id="select"
                          options={
                            selectedSyncType?.value === 'azure'
                              ? azureTenantsArray
                              : googleTenantsArray
                          }
                          components={{ tenantsOption }}
                          placeholder="Select tenant"
                          value={selectedTenant}
                          onChange={handleTenantChange}
                          isSearchable={true}
                          styles={colourStyles}
                        />
                      </label>
                    </div>
                    <div className="new-mapping-select mapping-select-group">
                      <label className="new-mapping-select-label">
                        From {selectedSyncType?.label} group(s)
                        <GroupsSelect
                          selectedTenant={{ ...selectedTenant, syncType: selectedSyncType.value }}
                          isMulti={true}
                          onChange={handleSyncGroupsChange}
                          isLoading={newMapLoading}
                          selectedGroup={selectedSyncGroups}
                          placeholder={'Select Groups'}
                          isDisabled={loading || !selectedTenant?.value}
                        />
                      </label>
                    </div>
                    <div className={`new-mapping-select mapping-select-group`}>
                      <label className="new-mapping-select-label">
                        To system group
                        <Select
                          id="select"
                          options={systemGroupsOptionList}
                          components={{ Option }}
                          placeholder="Select group"
                          value={selectedOptions}
                          onChange={handleSelect}
                          isSearchable={true}
                          closeMenuOnSelect={true}
                          isLoading={systemGroupsLoading}
                          styles={colourStyles}
                        />
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className="details-help">
                    <p>You have no integrations yet.</p>
                    <NavLink to={`/back_office/user/sync`}>
                      Please set up your integration first
                    </NavLink>
                  </div>
                )}
              </div>

              <div className="mapping-buttons-container">
                {!loading && !newMapLoading && !tenants.length ? (
                  <CustomizedTooltipDisabled
                    position={'top'}
                    text1={'Will be active after first sync with'}
                    text2={'Azure Active Directory or'}
                    text3={'Google Workspace'}
                    button={
                      <button
                        className="group-button group-button-simple"
                        onClick={async () => {
                          await changeGroupMapping({
                            selectedSyncGroups,
                            destGroup: selectedOptions.label,
                            tenantId: selectedTenant.id,
                          });
                        }}
                        disabled={
                          !selectedTenant ||
                          !selectedOptions.label ||
                          !selectedSyncGroups.length
                        }
                        type="submit"
                      >
                        Submit
                      </button>
                    }
                  />
                ) : (
                  <button
                    className="group-button group-button-simple"
                    onClick={() => {
                      changeGroupMapping({
                        selectedSyncGroups,
                        destGroup: selectedOptions.label,
                        tenantId: selectedTenant.id,
                      });
                    }}
                    disabled={
                      !selectedTenant ||
                      !selectedOptions ||
                      !selectedSyncGroups?.length
                    }
                    type="submit"
                  >
                    {newMapLoading ? <span className="mini-loader-csv"></span> : 'Submit'}
                  </button>
                )}
                <button
                  className="group-button-secondary"
                  onClick={() => setNewMappingCard(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <div className="users-main">
            <div>
              <div className="audit table">
                <table className="mapping-users-table">
                  <thead className="table-th">
                    <tr>
                      <th className="mapping-table">
                        <h2>Current mapping</h2>
                        {!newMappingCard && !loading && (
                          <div>
                            <button
                              className="group-button"
                              onClick={() => {
                                setNewMappingCard(true);
                              }}
                              disabled={loading}
                              type="submit"
                            >
                              Add new mapping
                            </button>
                          </div>
                        )}
                      </th>
                    </tr>

                    {!mappingListLoading && groupMapping?.length ? (
                      <tr className="mapping-users-not-clickable mapping-users-tr">
                        <th>System group</th>
                        <th>Sync group(s)</th>
                        <th>Sync method</th>
                        <th>Tenant name</th>
                        <th>Action</th>
                      </tr>
                    ) : !mappingListLoading && !groupMapping?.length ? (
                      <tr className="groups-description">
                        <th>
                          <p>There is no mapping yet.</p>
                        </th>
                      </tr>
                    ) : null}
                  </thead>

                  {mappingListLoading && <div style={{ display: 'flex', justifyContent: 'center',   height: '40px'}}>
                  <span className="mini-loader-csv"></span>
                </div>}

                  <tbody className="table-body">
                    {!mappingListLoading && groupMapping?.map(
                      (item, i) =>
                        item.remoteGroupNames?.length && (
                          <tr key={i} className={' mapping-users-tr'}>
                            <td className="mapping-td-email">
                              <span className="group-link">
                                <NavLink
                                  to={`/back_office/groups/${item.systemGroupName
                                    .split(' ')
                                    .join('_')}`}
                                >
                                  {item.systemGroupName}
                                </NavLink>
                              </span>
                            </td>
                            <td className="mapping-td-email">
                              {item.remoteGroupNames?.join(', ')}
                            </td>
                            <td className="mapping-sync">
                              {item.tenantType === 'azure' ? (
                                <img
                                  src={azure}
                                  alt="Logo"
                                  className="logo-group"
                                />
                              ) : (
                                <img
                                  src={google}
                                  alt="Logo"
                                  className="logo-group"
                                />
                              )}
                              {item.tenantType}
                            </td>
                            <td>{item.tenantName}</td>
                            <td className="mapping-td-edit">
                              <i
                                role="button"
                                className="map-edit"
                                onClick={() => {
                                  handleChangeEditedTenant(item.tenantId);
                                  setEditedGroup(item.systemGroupName);
                                  setSyncModal(true);
                                }}
                              />
                              <i
                                role="button"
                                className="map-delete"
                                onClick={() =>
                                  deleteMap(item.systemGroupName, item.tenantId, item.tenantType)
                                }
                              />
                            </td>
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div >
      )}

      {
        syncModal && <SyncModal
          groupName={editedGroup}
          tenant={editedTenant}
          setSyncModal={setSyncModal}
          syncModal={syncModal}
          setIsLoading={setIsLoading}
          navigaveToGroup={false}
          onMappingCreated={getGroupMap}
        />
      }
    </>
  );
};
