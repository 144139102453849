import React, { useEffect, useState } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import colourStyles from '../../styles/colour-style';
import { GroupsOption } from '../ReactSelect/ReactSelectOption';
import { AsyncPaginate } from 'react-select-async-paginate';
import { itemsOnPage } from "../../config/config";

export const GroupsSelect = ({
  selectedTenant, classNames,
  isMulti, onChange, selectedGroup,
  placeholder, isDisabled,
  hideSelectedOptions, isLoading,
  location, department
}) => {
  const { request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token } = useAuth();
  const { decryptData } = useCrypto();
  const [inputValue, setInputValue] = useState('');
  const [initialGroups, setInitialGroups] = useState([]);
  const [googleNextToken, setGoogleNextToken] = useState(null);
  const [azureNextToken, setAzureNextToken] = useState(null);
  const [placeholderText, setPlaceholderText] = useState(placeholder);
  const [tenant, setTenant] = useState(null);
  const [isGroupsLoading, setIsGroupsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  useEffect(() => {
    setTenant(selectedTenant);
    setSelectedLocation(location);
    setSelectedDepartment(department);
    setInitialGroups([]);
  }, [selectedTenant, location, department]);

  const getGroupsFunction = async (search, loadedOptions, additional) => {
    try {
      if (token) {
        if (!search.length) {
          setIsGroupsLoading(true)

          const remoteGroups = await request(`/back_office/api/groups/sync_group_list?tenantId=${tenant?.id}${selectedLocation ? `&location=${selectedLocation}` : ''}${selectedDepartment ? `&department=${selectedDepartment}` : ''}`, 'GET', null, {
            Authorization: `Bearer ${token}`,
          })
          const groups = decryptData(remoteGroups)
          const groupsOptions = groups.map(e => {
            return {
              value: e.id,
              label: e.name,
            }
          });
          const initialOptions = { options: groupsOptions, hasMore: false }

          setInitialGroups(initialOptions)
          setAzureNextToken(null)
          setGoogleNextToken(null)
          setIsGroupsLoading(false)
          return initialOptions
        }
        else if (search.length) {
          const results = initialGroups.options?.filter(e => e.label.toLowerCase().includes(search.toLowerCase()))

          if (results?.length) {
            return {
              options: results,
              hasMore: false,
            };
          } else {
            if (tenant.id && tenant.syncType === 'azure') {
              const fetched = await requestWithSecondaryLoading(
                `/back_office/api/groups/sync_group_list_azure?groupQuery=${search}&tenantId=${tenant?.id}&limit=${itemsOnPage}${azureNextToken ? `&nextPageToken=${azureNextToken}` : ''}`,
                'GET',
                null,
                {
                  Authorization: `Bearer ${token}`,
                }
              );

              const { groups, nextToken } = decryptData(fetched)
              setAzureNextToken(nextToken);

              const groupsOptions = groups.map(e => {
                return {
                  value: e.id,
                  label: e.displayName,
                }
              });

              return {
                options: groupsOptions || [],
                hasMore: !!nextToken,
              };
            } else if (tenant.id && tenant.syncType === 'google') {
              const fetched = await requestWithSecondaryLoading(
                `/back_office/api/groups/sync_group_list_google?groupQuery=${inputValue}&tenantId=${tenant?.id}&limit=${itemsOnPage}${googleNextToken ? `&nextPageToken=${googleNextToken}` : ''}`,
                'GET',
                null,
                {
                  Authorization: `Bearer ${token}`,
                }
              );

              const { finalGroups, pageToken } = decryptData(fetched)
              setGoogleNextToken(pageToken);

              const groups = finalGroups.map(e => {
                return {
                  value: e.id,
                  label: e.name,
                }
              });

              return {
                options: groups,
                hasMore: !!pageToken,
              };
            } else {
              return {
                options: [],
                hasMore: false,
              };
            }
          }
        }
      }
      setIsGroupsLoading(false)
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const shouldLoadMore = (
    scrollHeight,
    clientHeight,
    scrollTop
  ) => {
    const bottomBorder = (scrollHeight - clientHeight) / 3;

    return bottomBorder < scrollTop;
  };

  const handleInputChange = (e) => {
    setInputValue(e)
    setPlaceholderText(placeholder)
    setGoogleNextToken(null)
    setAzureNextToken(null)
  }

  return (
    <>
      <AsyncPaginate
        id="select"
        key={`${JSON.stringify(tenant)}-${location}-${department}`}
        placeholder={placeholderText}
        className={classNames}
        classNamePrefix="react-select"
        value={selectedGroup}
        onChange={onChange}
        isSearchable={true}
        inputValue={inputValue}
        onInputChange={(e) => handleInputChange(e)}
        styles={colourStyles}
        loadOptions={getGroupsFunction}
        isLoading={secondaryLoading || isLoading || isGroupsLoading}
        isDisabled={isDisabled}
        isClearable
        isMulti={isMulti}
        closeMenuOnSelect={true}
        optionComponent={GroupsOption}
        cacheOptions={true}
        defaultInputValue={inputValue}
        onFocus={() => setPlaceholderText("")}
        backspaceRemovesValue={true}
        hideSelectedOptions={hideSelectedOptions}
        debounceTimeout={300}
        shouldLoadMore={shouldLoadMore}
        additional={{
          initialGroups: initialGroups,
        }}
      /></>
  );
};
