import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../../components/Loader/Loader';
import { useHttp } from '../../../hooks/http.hook';
import { useAuth } from '../../../hooks/auth.hook';
import { useSortableData } from '../../../hooks/sort.hook';
import { getClassNamesFor } from '../../../common/getClassNamesFor';
import { AuthContext } from '../../../context/AuthContext';
import button from '../../..//images/icons/Button.svg'
import edit from '../../..//images/icons/Eye.svg'
import { useCrypto } from '../../../hooks/crypto.hook';
import Modal from 'react-modal';
import ReactSelect from '../../../components/ReactSelect/ReactSelect';
import { EmailTemplateOption } from '../../../components/ReactSelect/ReactSelectOption';
import './EmailTemplates.css';
import { customModalStyles } from '../../../config/config';

export const EmailTemplates = () => {
  const { loading, request } = useHttp();
  const { token } = useAuth();
  const { decryptData, encryptData } = useCrypto();
  const auth = useContext(AuthContext);

  const [allTemplates, setAllTemplates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [card, setCard] = useState(1);
  const [addCard, setAddCard] = useState(false)
  const [selectedRow, setSelectedRow] = useState(-1);
  const [previewModal, setPreviewModal] = useState(false);

  const { items, requestSort, sortConfig } = useSortableData(templates);

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const fetchAllTemplates = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/all_templates', 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptTemplates = decryptData(fetched);
        setAllTemplates(decryptTemplates)

        return decryptTemplates;
      }

    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const fetchTemplates = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/templates', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptTemplates = decryptData(fetched);
        setTemplates(decryptTemplates)

        return decryptTemplates;
      }

    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const submitHandler = async (e) => {
    try {
      const data = encryptData(selectedOptions);
      const response = await request('/back_office/api/exclusions/templates', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })
      auth.showToastMessage(response.error, response.message);
      setSelectedOptions([])
      setSelectedRow(-1)
      setCard(3);
      setAddCard(false);
      fetchTemplates();
      fetchAllTemplates();

    } catch (error) { }
  }

  useEffect(() => {
    fetchAllTemplates();
  }, [fetchAllTemplates]);

  useEffect(() => {
    fetchTemplates()
  }, [fetchTemplates]);

  useEffect(() => {
    if (!templates.length) setCard(1)
    else setCard(3)
  }, [templates.length])

  const deleteTemplate = async () => {
    try {
      const data = encryptData(selectedRow);
      const response = await request('/back_office/api/exclusions/templates', 'DELETE', { data }, {
        Authorization: `Bearer ${token}`
      })
      auth.showToastMessage(response.error, response.message);
      setCard(3)
      fetchTemplates();
      fetchAllTemplates();

    } catch (error) { }
  }

  const optionList = allTemplates.map(template => (
    { value: template.id, label: template.name, caption: template.subject }
  ))

  if (loading) {
    return <Loader />
  }

  return (
    <> {!loading &&
      <div className="templates-main">
        {card === 1 &&
          <div>
            <p className="templates-description">
              There are no exclusions yet.
            </p>
            <button
              className='templates-button'
              onClick={() => setCard(2)}
              disabled={loading}
              type="submit"
            >
              Add templates
            </button>
          </div>
        }

        {card === 2 &&
          <div>
            <p className="templates-add-title">
              Add template
            </p>

            <div className='email-template-select-multi'>
              <label className='userslist-select-label'>
                <ReactSelect
                  classNames={'multiselect-user'}
                  options={optionList}
                  optionComponent={EmailTemplateOption}
                  placeholder={'Select templates'}
                  value={selectedOptions}
                  onChange={handleSelect}
                  isSearchable={true}
                  isMulti={true}
                  optionHeight={65}
                  rows={6}
                />
              </label>
            </div>

            <button
              className='templates-button templates-button-simple'
              onClick={submitHandler}
              disabled={!selectedOptions.length}
              type="submit"
            >
              Add templates
            </button>
          </div>
        }

        {card === 3 &&
          <div>
            {!addCard
              ?
              <div className='templates-actions'>
                <p className="audit-desc">Exclusion list of email templates</p>
                <button
                  className='templates-button'
                  onClick={() => { setAddCard(!addCard); setSelectedRow(-1) }}
                  disabled={!selectedOptions}
                  type="submit"
                >
                  Add templates
                </button>
              </div>
              :
              <div className="templates-add-actions">
                <p className="templates-add-title">
                  Add template
                </p>

                <div className='email-template-select-multi'>
                  <label className='userslist-select-label'>
                    <ReactSelect
                      classNames={'multiselect-user'}
                      options={optionList}
                      optionComponent={EmailTemplateOption}
                      placeholder={'Select templates'}
                      value={selectedOptions}
                      onChange={handleSelect}
                      isSearchable={true}
                      isMulti={true}
                      optionHeight={65}
                      rows={6}
                    />
                  </label>
                </div>
                <button
                  className='templates-button templates-button-simple'
                  onClick={submitHandler}
                  disabled={!selectedOptions.length}
                  type="submit"
                >
                  Add templates
                </button>

                <p className='templates-add-subtitle'>Exclusion list of email templates</p>
              </div>
            }

            <div className="audit table">
              <table className="template-table">
                <thead
                  className="table-th"
                >
                  <tr
                    className="template-not-clickable template-tr"
                  >
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('name')}
                        className={getClassNamesFor('name', sortConfig)}
                      >
                        Email template
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort('subject')}
                        className={getClassNamesFor('subject', sortConfig)}
                      >
                        Subject
                      </button>
                    </th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody
                  className="template-table-body"
                >
                  {items.map((t) => (
                    <tr
                      key={t.id}
                      onClick={() => setSelectedRow(t.id)}
                      className={"template-clickable-row template-tr ".concat(selectedRow === t.id ? "template-selected" : "")}
                    >
                      <td>{t?.name}</td>
                      <td className='td-email'>{t?.subject}</td>
                      <td>
                        <div className='template-delete-container'>

                          <button
                            type='button'
                            className='template-button-delete'
                            onClick={() => {
                              setSelectedRow(t.id)
                              setPreviewModal(true)
                            }}
                          // disabled={selectedRow !== t.id}
                          >
                            <img src={edit} alt='btn'></img>
                          </button>
                          <button
                            type='button'
                            className='template-button-delete'
                            onClick={deleteTemplate}
                            disabled={selectedRow !== t.id}
                          >
                            <img src={button} alt='btn'></img>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>
        }
      </div>
    }

      <Modal
        isOpen={previewModal}
        onRequestClose={() => setPreviewModal(false)}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <div className='templates-modal' dangerouslySetInnerHTML={{
          __html: `${items.find(e => e.id === selectedRow)?.htmlMessage || items.find(e => e.id === selectedRow)?.displayHtml}`
        }}>
        </div>
      </Modal>
    </>
  )
}
