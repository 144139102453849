import { useState, useMemo } from 'react';

const riskLevelSortOrder = {
  'Low Risk': 1,
  'Moderate Risk': 2,
  'High Risk': 3,
};

export const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = items.filter((item) => item[sortConfig?.key] !== null);
    let nullItems = items.filter((item) => item[sortConfig?.key] === null);
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === 'riskScoreLevel') {
          const aOrder = riskLevelSortOrder[a[sortConfig.key]] || 0;
          const bOrder = riskLevelSortOrder[b[sortConfig.key]] || 0;

          return sortConfig.direction === 'ascending'
            ? aOrder - bOrder
            : bOrder - aOrder;
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
      });
    }

    if (
      (sortConfig?.key === 'lastTrainingInteraction' ||
        sortConfig?.key === 'trainingCompletionDate') &&
      sortConfig?.direction === 'ascending'
    ) {
      return [...nullItems, ...sortableItems];
    } else {
      return [...sortableItems, ...nullItems];
    }
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
