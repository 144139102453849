import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import moment from 'moment';
import ReactSelect from '../../ReactSelect/ReactSelect';
import { DateRangeOption, DepartmentOption, LocationOption, TenantOption } from '../../ReactSelect/ReactSelectOption';
import { AnalyticsGeneralContext } from '../../../context/AnalyticsGeneralContext';
import { dateRangeOptionsAnalytics, lastThirtyDaysChunk, syncOptions } from '../../../config/config';
import { ReactComponent as Description } from '../../../images/icons/description.svg';
import { GenerateReportModal } from '../GenerateReportModal/GenerateReportModal';
import { GroupsSelect } from '../../GroupsSelect/GroupsSelect';
import './UserAnalyticsFilterBar.css';
import { CustomizedTooltipDisabled } from '../../Tooltips/Tooltip';
import { AuthContext } from '../../../context/AuthContext';

export const UserAnalyticsFilterBar = ({
  loading, tenants, departments, locations, minMailingDate, setMinMailingDate, selectedTenant,
  setSelectedTenant, selectedDepartment, setSelectedDepartment, dateRangeOptionsList,
  filteredMinMailingDate, selectedLocation, setSelectedLocation, selectedGroup, setSelectedGroup,
  groupIdParam, selectedDateRange, setSelectedDateRange, setDateRangeOptionsList, maxMailingDate,
  setMaxMailingDate, summaryPageSelect, riskPageSelect, phishingPageSelect, educationPageSelect,
  generateReportCallback, generateReportButtonDisabled, generateReportButtonVisible, setNoData
}) => {
  const { minMailingDate: contextMinMailingDate } = useContext(AnalyticsGeneralContext);
  const { user } = useContext(AuthContext);
  const filterBarRef = useRef(null);

  const [customDateRange, setCustomDateRange] = useState(false);
  const [showFilterBar, setShowFilterBar] = useState(true);
  const [scrolledDown, setScrolledDown] = useState(false);
  const [buttonPositionX, setButtonPositionX] = useState(10);
  const [dragStartX, setDragStartX] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [generateReportModal, setGenerateReportModal] = useState(false);

  const syncTypes = syncOptions.map(type => type.value);
  const openGenerateReportModal = () => {
    setGenerateReportModal(true);
    if (selectedDateRange.value === -1) {
      setMaxMailingDate(moment().format('YYYY-MM-DD'));
      setSelectedDateRange(dateRangeOptionsAnalytics[0])
    }
  };

  const closeGenerateReportModal = () => {
    setGenerateReportModal(false);
  };

  const resetDateRange = useCallback(() => {
    setSelectedDateRange(dateRangeOptionsAnalytics[0]);
    setNoData(false);
    setMinMailingDate(moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD'));
  }, [setSelectedDateRange, setMinMailingDate, setNoData]);

  const handleDepartmentChange = useCallback((data) => {
    setSelectedDepartment(data);
    resetDateRange();
  }, [resetDateRange, setSelectedDepartment]);

  const handleLocationChange = useCallback((data) => {
    setSelectedLocation(data);
    resetDateRange();
  }, [resetDateRange, setSelectedLocation]);

  const handleTenantChange = useCallback((data) => {
    setSelectedTenant(data);
    setSelectedGroup(null);
    resetDateRange();
  }, [resetDateRange, setSelectedTenant, setSelectedGroup]);

  const handleGroupChange = useCallback((data) => {
    setSelectedGroup(data);
    resetDateRange();
  }, [resetDateRange, setSelectedGroup]);

  const handleDateRangeChange = useCallback((data) => {
    if (!data || data?.value === 0) {
      setMinMailingDate(contextMinMailingDate);
      setCustomDateRange(false);
    } else if (data?.value === -1) {
      setCustomDateRange(true);
    } else {
      setMinMailingDate(moment().subtract(data.value, 'days').format('YYYY-MM-DD'));
      setCustomDateRange(false);
    }
    setMaxMailingDate(moment().format('YYYY-MM-DD'));
    setSelectedDateRange(data);
  }, [contextMinMailingDate, setMaxMailingDate, setMinMailingDate, setSelectedDateRange]);

  useEffect(() => {
    if (selectedDateRange?.value === -1) {
      setCustomDateRange(true);
    }
  }, [selectedDateRange]);

  const filteredDateRangeOptions = useMemo(() => {
    const minDateDiff = moment().diff(moment(filteredMinMailingDate), 'days');
    return dateRangeOptionsAnalytics.filter(option =>
      (option.value <= minDateDiff) || option.value === 0 || option.value === -1
    );
  }, [filteredMinMailingDate]);

  useEffect(() => {
    setDateRangeOptionsList(filteredDateRangeOptions);
  }, [filteredDateRangeOptions, setDateRangeOptionsList]);

  useEffect(() => {
    const handleScroll = () => {
      const scrolledDown = 140;
      setScrolledDown(window.scrollY > scrolledDown);

      if (window.scrollY <= scrolledDown)
        setShowFilterBar(true);
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const filterBarRect = filterBarRef.current?.getBoundingClientRect();

    if (filterBarRect) {
      const newRight = filterBarRect.right - event.clientX;
      const minPosX = 10;
      const maxPosX = filterBarRect.width - 45;

      if (newRight >= minPosX && newRight <= maxPosX) {
        setButtonPositionX(newRight);
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = (event) => {
    setDragStartX(event.clientX);
    setIsDragging(false);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMoveDragStart = (event) => {
    if (!isDragging && Math.abs(event.clientX - dragStartX) > 10) {
      setIsDragging(true);
    }
  };

  const handleMouseClick = () => {
    if (!isDragging) {
      setShowFilterBar((prev) => !prev);
    }
  };

  return (
    <>
      <div
        className={cn('user-analytics-input-container', {
          'user-analytics-input-container--fixed': showFilterBar && scrolledDown,
          'user-analytics-input-container--show': scrolledDown
        })}
        ref={filterBarRef}
      >
        <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--tenant', {
          'input-container-analytics-item--disabled': loading
        })}>
          <ReactSelect
            options={tenants}
            optionComponent={TenantOption}
            placeholder={'Tenant'}
            classNames={'option-select-analytics'}
            value={selectedTenant}
            onChange={handleTenantChange}
            isSearchable={true}
            isClearable={true}
            optionHeight={30}
            rows={10}
          />
        </div>

        <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--group', {
          'input-group-container-analytics-item--disabled': loading || !selectedTenant?.value || user.paymentPlanId !== 4
        })}>
          {(syncTypes?.includes(selectedTenant?.syncType) || groupIdParam)
            ? <GroupsSelect
              selectedTenant={selectedTenant}
              classNames={'option-select-analytics'}
              isMulti={false}
              onChange={handleGroupChange}
              selectedGroup={selectedGroup}
              placeholder={'Group'}
              isDisabled={!selectedTenant?.value}
              location={selectedLocation?.value}
              department={selectedDepartment?.value}
            />
            : <CustomizedTooltipDisabled
              position={"top"}
              text1={user.paymentPlanId !== 4 ? "Upgrade" : "Please select your Azure"}
              text2={user.paymentPlanId !== 4 ? "required" : "or Google tenant first."}
              button={
                <GroupsSelect
                  selectedTenant={selectedTenant}
                  classNames={'option-select-analytics'}
                  isMulti={false}
                  onChange={handleGroupChange}
                  selectedGroup={selectedGroup}
                  placeholder={'Group'}
                  isDisabled={!selectedTenant?.value}
                />
              }
            />
          }
        </div>

        <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--department', {
          'input-container-analytics-item--disabled': loading
        })}>
          <ReactSelect
            options={departments}
            optionComponent={DepartmentOption}
            placeholder={'Department'}
            classNames={'option-select-analytics'}
            value={selectedDepartment}
            onChange={handleDepartmentChange}
            isSearchable={true}
            isClearable={true}
            optionHeight={32}
            rows={10}
          />
        </div>
        <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--location', {
          'input-container-analytics-item--disabled': loading
        })}>
          <ReactSelect
            options={locations}
            optionComponent={LocationOption}
            placeholder={'Location'}
            classNames={'option-select-analytics'}
            value={selectedLocation}
            onChange={handleLocationChange}
            isSearchable={true}
            isClearable={true}
            optionHeight={32}
            rows={10}
          />
        </div>

        <GenerateReportModal
          isOpen={generateReportModal}
          onRequestClose={closeGenerateReportModal}
          generateReportCallback={generateReportCallback}
          generateReportButtonDisabled={generateReportButtonDisabled}
          loading={loading}
          tenants={tenants}
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
          selectedGroup={selectedGroup}
          groupIdParam={groupIdParam}
          setSelectedGroup={setSelectedGroup}
          departments={departments}
          selectedDepartment={selectedDepartment}
          setSelectedDepartment={setSelectedDepartment}
          locations={locations}
          selectedLocation={selectedLocation}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
          minMailingDate={minMailingDate}
          setMinMailingDate={setMinMailingDate}
          setMaxMailingDate={setMaxMailingDate}
          setSelectedLocation={setSelectedLocation}
          summaryPageSelect={summaryPageSelect}
          riskPageSelect={riskPageSelect}
          phishingPageSelect={phishingPageSelect}
          educationPageSelect={educationPageSelect}
        />

        <button
          className={cn("user-analytics-scroll-button", {
            'user-analytics-scroll-button--show': scrolledDown,
            'user-analytics-scroll-button--fixed': !showFilterBar,
          })}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMoveDragStart}
          onClick={handleMouseClick}
          style={{ right: `${buttonPositionX}px` }}
          title={!showFilterBar ? "Open Filter Bar" : "Close Filter Bar"}
        >
          {showFilterBar ? '▲' : '▼'}
        </button>
      </div>
      <div className='analytics-report-container'>
        <div className='user-analytics-input-container'
        >
          <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--date-range', {
            'input-container-analytics-item--disabled': loading
          })}>
            <ReactSelect
              options={dateRangeOptionsList}
              optionComponent={DateRangeOption}
              placeholder={'Date Range'}
              onChange={handleDateRangeChange}
              value={selectedDateRange}
              isSearchable={false}
              isDisabled={loading}
              optionHeight={28}
              rows={10}
            />
          </div>
          {customDateRange &&
            <div className='analytics-date-range-container'>
              <div className='input-field-history-date-range-container'>
                <div className={cn("input-field-history", {
                  'input-container-analytics-item--disabled': loading
                })}>
                  <DatePicker
                    selected={minMailingDate ? moment(minMailingDate).format() : null}
                    onChange={(date) => {
                      setMinMailingDate(moment(date).format('YYYY-MM-DD'));
                      setSelectedDateRange({ value: -1, label: 'Custom range' });
                    }}
                    selectsStart
                    onKeyDown={(e) => e.preventDefault()}
                    minDate={filteredMinMailingDate ? moment(filteredMinMailingDate).format() : null}
                    maxDate={maxMailingDate ? moment(maxMailingDate).format() : null}
                    className={cn("input-edit-history", { "input-edit-history-loading": loading })}
                    disabled={loading}
                    placeholderText="Enter Date"
                  />
                </div>
                <p>&#11020;</p>
                <div className={cn("input-field-history", {
                  'input-container-analytics-item--disabled': loading
                })}>
                  <DatePicker
                    selected={maxMailingDate ? moment(maxMailingDate).format() : null}
                    onChange={(date) => {
                      setMaxMailingDate(moment(date).format('YYYY-MM-DD'));
                      setSelectedDateRange({ value: -1, label: 'Custom range' });
                    }}
                    selectsStart
                    onKeyDown={(e) => e.preventDefault()}
                    minDate={minMailingDate ? moment(minMailingDate).format() : null}
                    maxDate={maxMailingDate ? moment().format() : null}
                    className={cn("input-edit-history", { "input-edit-history-loading": loading })}
                    disabled={loading}
                    placeholderText="Enter Date"
                  />
                </div>
              </div>
            </div>
          }
        </div>
        {generateReportButtonVisible &&
          <div className='analytics-report-button'>
            {(!generateReportButtonDisabled && !loading) ? (
              <button
                className='generate-report-button'
                onClick={openGenerateReportModal}
                disabled={generateReportButtonDisabled || loading}
              >
                <Description />
                Create Report Shedule [Beta]
              </button>
            ) : (
              <button
                className="csv-download-loading"
                type="button"
                disabled={generateReportButtonDisabled || loading}
              >
                <span className="mini-loader-csv"></span>
              </button>
            )}
          </div>
        }
      </div>
    </>
  )
};
