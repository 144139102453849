import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useSortableData } from '../../hooks/sort.hook';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import edit from '../.././images/icons/Pen.svg';
import { useCrypto } from '../../hooks/crypto.hook';
import './UsersGroup.css';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import azure from '../../images/icons/Azure.svg';
import google from '../../images/icons/Google (colors).svg';
import csv from '../../images/icons/File Red.svg';
import filter from '../../images/icons/filter-icon.svg';
import arrowdown from '../.././images/icons/caret-down.svg';
import { AuthContext } from '../../context/AuthContext';
import { UsersFilters } from '../../components/Filters/UsersFilters';

export const UsersGroup = () => {
  const { loading, request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token } = useAuth();
  const { decryptData } = useCrypto();
  const { group: groupName } = useParams();
  const [users, setUsers] = useState([]);
  const [exclusionsShown, setExclusionsShown] = useState(false);
  const [filtersShown, setFiltersShown] = useState(false);
  const [openGroupTenantId, setOpenGroupTenantId] = useState(null);
  const [exclusions, setExclusions] = useState([]);
  const { allGroups } = useContext(AuthContext);
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [groupMapping, setGroupMapping] = useState([]);
  const { items, requestSort, sortConfig } = useSortableData(filteredUsers);

  const normalizedGroupName = groupName?.split('_').join(' ');
  const group = allGroups.find(e => e.label === normalizedGroupName);
  const navigate = useNavigate();

  const getGroupMap = useCallback(async () => {
    try {
      if (token && group?.id) {
        const fetched = await request(
          `/back_office/api/groups/groups_map/${group.id}`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptMapping = decryptData(fetched);

        setGroupMapping(decryptMapping);

        return decryptMapping;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, group]);

  const fetchAllUsers = useCallback(async () => {
    try {
      if (token) {
        const fetched = await requestWithSecondaryLoading(
          `/back_office/api/groups/${groupName}/users`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptUsers = decryptData(fetched);

        if (!decryptUsers.length && group?.id) {
          const fetched = await requestWithSecondaryLoading(
            `/back_office/api/groups/groups_map/${group.id}`,
            'GET',
            null,
            {
              Authorization: `Bearer ${token}`,
            }
          );

          const decryptMapping = decryptData(fetched);
          if (!decryptMapping.length) {
            return navigate('/back_office/groups');
          }
        }

        setUsers(decryptUsers);
        setFilteredUsers(decryptUsers);
        setFiltersShown(false)
        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, groupName]);

  const getExclusions = useCallback(async () => {
    try {
      if (token && groupName) {
        const fetched = await request(
          `/back_office/api/groups/${groupName}/exclusions`,
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );

        const decryptExclusions = decryptData(fetched);

        setExclusions(decryptExclusions);
        setOpenGroupTenantId(null)
        return decryptExclusions;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, groupName]);

  const getExclusionOptions = (exclusions, tenantId) => {
    const exclusionFromTenant = exclusions.filter((e) => e.tenantId === tenantId);

    const options = exclusionFromTenant.map((e, i) => (
      <li key={i}>{`${e.firstName} ${e.secondName} <${e.email}>`}</li>
    ));

    return options;
  };

  useEffect(() => {
    getGroupMap();
  }, [getGroupMap]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  useEffect(() => {
    getExclusions();
  }, [getExclusions]);


  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!loading && !secondaryLoading && (
        <div className="users-main">
          <div>
            <div className="group-button-container">
              <div>
                {!!groupMapping.length && (
                  <h4 className="group-sync-title">Synced</h4>
                )}
                <ul>
                  {groupMapping.map((item) => (
                    <li key={item.tenantId}>
                      <div className="group-sync-tenant">
                        {item.tenantType === 'azure'
                          ? <img src={azure} alt="Logo" className="logo-group" />
                          : item.tenantType === 'google'
                            ? <img src={google} alt="Logo" className="logo-group" />
                            : <></>
                        }
                        <div>
                          <button
                            className="excl-button"
                            onClick={() =>
                              openGroupTenantId !== item.tenantId 
                                ? setOpenGroupTenantId(item.tenantId) 
                                : setOpenGroupTenantId(null)
                            }
                          >
                            <div className="excl-list-title">
                              {item.tenantName}
                              {openGroupTenantId !== item.tenantId ? (
                                <img src={arrowdown} alt="arrow down" />
                              ) : (
                                <img
                                  style={{ transform: 'rotate(180deg)' }}
                                  src={arrowdown}
                                  alt="arrow up"
                                />
                              )}
                            </div>
                          </button>

                          {openGroupTenantId === item.tenantId && (
                            <div className="excl-list-title">
                              <span>{`Groups: `}</span>
                              {item.remoteGroupNames.join(', ')}
                            </div>)}
                        </div>
                      </div>

                      {!!getExclusionOptions(exclusions, item.tenantId).length && (
                        <div className="group-sync-tenant">
                          {
                            <button
                              className="excl-button"
                              onClick={() =>
                                setExclusionsShown((prev) => !prev)
                              }
                            >
                              <div className="excl-list-title">
                                <strong>Exclusions</strong>
                                {!exclusionsShown ? (
                                  <img src={arrowdown} alt="arrow down" />
                                ) : (
                                  <img
                                    style={{ transform: 'rotate(180deg)' }}
                                    src={arrowdown}
                                    alt="arrow up"
                                  />
                                )}
                              </div>
                            </button>
                          }
                        </div>
                      )}

                      {exclusionsShown && (
                        <ul className="excl-list">
                          {getExclusionOptions(exclusions, item.tenantId)}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
              <div style={{ display: 'flex', alignContent: 'center' }}>
                <span className="filter-link">
                  <button title='Filters' onClick={() => setFiltersShown((prev) => !prev)}>
                    <img src={filter} alt="filter" />
                  </button>
                </span>
                <NavLink
                  to={`/back_office/groups/${groupName}/edit`}
                  className="button-link"
                >
                  <button type="button">
                    <img src={edit} alt="edit"></img>
                    Edit
                  </button>
                </NavLink>
              </div>
            </div>

            {filtersShown && (
              <UsersFilters users={users} setFilteredUsers={setFilteredUsers} />
            )}

            {!secondaryLoading && !loading && !users.length && (
              <div className="no-groups-message">
                No users found
              </div>
            )}

            <div className="audit table">
              {!!items.length && (
                <table className="group-users-table">
                  <thead className="table-th">
                    <tr className="group-users-not-clickable group-users-tr">
                      <th>User ID</th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('email')}
                          className={getClassNamesFor('email', sortConfig)}
                        >
                          Email
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('firstName')}
                          className={getClassNamesFor('firstName', sortConfig)}
                        >
                          First name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('secondName')}
                          className={getClassNamesFor('secondName', sortConfig)}
                        >
                          Last name
                        </button>
                      </th>
                      <th>
                        <button
                          type="button"
                          onClick={() => requestSort('tenantName')}
                          className={getClassNamesFor('tenantName', sortConfig)}
                        >
                          Tenant
                        </button>
                      </th>
                      <th>Sync method</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {items.map((u) => (
                      <tr key={u.id} className={' group-users-tr '}>
                        <td className="group-td-email">{u.id}</td>
                        <td className="group-td-email">{u.email}</td>
                        <td>{u?.firstName}</td>
                        <td>{u?.secondName}</td>
                        <td className="group-td-email">{u.tenantName}</td>
                        <td className="list-td-email">
                          <span>
                            {u.syncType && (
                              <img
                                src={
                                  u.syncType === 'azure'
                                    ? azure
                                    : u.syncType === 'google'
                                      ? google
                                      : u.syncType === 'csv'
                                        ? csv
                                        : ''
                                }
                                alt="Logo"
                                className="list-td-email-icon"
                              ></img>
                            )}
                            {u.syncType}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
