import { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';
import { AnalyticsGeneralContext } from '../../../context/AnalyticsGeneralContext';
import { AuthContext } from '../../../context/AuthContext';
import { useHttp } from '../../../hooks/http.hook';
import { useCrypto } from '../../../hooks/crypto.hook';
import { dateRangeOptionsAnalytics, lastThirtyDaysChunk } from '../../../config/config';
import { UserAnalyticsFilterBar } from '../UserAnalyticsFilterBar/UserAnalyticsFilterBar';
import { PhishingMetrics } from './PhishingMetrics';
import { TrendGraph } from '../TrendGraph/TrendGraph';
import { ProneTable } from '../ProneTable/ProneTable';
import { TrendGraphBar } from '../TrendGraph/TrendGraphBar';
import './Phishing.css';

export const Phishing = () => {
  const {
    tenants: contextTenants,
    departments: contextDepartments,
    locations: contextLocations
  } = useContext(AnalyticsGeneralContext);

  const { token, showToastMessage } = useContext(AuthContext);
  const { request, loading, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { encryptData, decryptData } = useCrypto();
  const location = useLocation();

  const [tenants, setTenants] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [minMailingDate, setMinMailingDate] = useState(moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD'));
  const [maxMailingDate, setMaxMailingDate] = useState(moment().format('YYYY-MM-DD'));
  const [filteredMinMailingDate, setFilteredMinMailingDate] = useState(null);
  const [phishingData, setphishingData] = useState({});
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [dateRangeOptionsList, setDateRangeOptionsList] = useState(dateRangeOptionsAnalytics);
  const [selectedDateRange, setSelectedDateRange] = useState(dateRangeOptionsAnalytics[0]);
  const [tableData, setTableData] = useState([{
    title: 'Phish Click Table',
    columns: ['User', 'Phish Click Count'],
    data: [],
  }]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [graphData, setGraphData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    setTenants(contextTenants);
    setDepartments(contextDepartments);
    setLocations(contextLocations);
  }, [contextDepartments, contextLocations, contextTenants]);

  const generateReport = async (receivers, summaryPageSelect, riskPageSelect, phishingPageSelect, educationPageSelect) => {
    try {
      if (!receivers.length) {
        showToastMessage('Error', 'Please select at least one receiver');
        return;
      }
      const receiversData = receivers.map(receiver => ({
        email: receiver.email,
        firstName: receiver.firstName,
        lastName: receiver.lastName || receiver.secondName
      }));

      setDisabled(true);
      const data = encryptData({
        department: selectedDepartment ? selectedDepartment.label : null,
        location: selectedLocation ? selectedLocation.label : null,
        groupId: selectedGroup ? selectedGroup.value : null,
        groupName: selectedGroup ? selectedGroup.label : null,
        tenantId: selectedTenant ? selectedTenant.id : null,
        startDate: minMailingDate,
        endDate: maxMailingDate,
        dateRange: selectedDateRange ? selectedDateRange.value : null,
        receiversData,
        summaryPageSelect,
        riskPageSelect,
        phishingPageSelect,
        educationPageSelect
      });

      const result = await request('/back_office/api/analytics/generate_report', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      });

      showToastMessage(result.error, result.message);
      if (result.message) return true;
      return false;
    } catch (error) {
      console.error('Error generating report:', error);
      return false;
    } finally {
      setDisabled(false);
    }
  }

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const dateRangeParam = params.get('date_range');
  const startDateParam = params.get('start_date');
  const endDateParam = params.get('end_date');
  const companyId = params.get('company_id');
  const tenantId = params.get('tenant_id');
  const departmentParam = params.get('department');
  const locationParam = params.get('location');
  const groupIdParam = params.get('group_id');
  const groupNameParam = params.get('group_name');

  const fetchPhishingData = useCallback(async () => {
    try {
      if (token && minMailingDate) {
        const data = encryptData({
          department: selectedDepartment ? selectedDepartment.label : null,
          location: selectedLocation ? selectedLocation.label : null,
          groupId: selectedGroup ? selectedGroup.value : null,
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate,
          maxMailingDate,
          dateRange: selectedDateRange ? selectedDateRange.value : null
        });
        const link = `?company_id=${companyId ?? ''}&tenant_id=${tenantId ?? ''}&department=${departmentParam ?? ''}` +
          `&location=${locationParam ?? ''}&start_date=${startDateParam ?? ''}&end_date=${endDateParam ?? ''}` +
          `&date_range=${dateRangeParam ?? ''}&group_id=${groupIdParam ?? ''}`;
        const responsePhishingData = await request('/back_office/api/analytics/phishing' + link, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptPhishingData = decryptData(responsePhishingData);

        if (!Object.keys(decryptPhishingData).length) {
          setNoData(true);

          return;
        }

        setphishingData(decryptPhishingData);

        setFilteredMinMailingDate(decryptPhishingData.filteredMinMailingDate);
        setTableData(decryptPhishingData?.riskClickTableData || []);

        let filteredTenants = contextTenants;
        if (selectedDepartment || selectedLocation) {
          filteredTenants = filteredTenants?.filter(tenant =>
            decryptPhishingData?.tenantIds?.includes(tenant.id)
          );
        }

        let filteredDepartments = contextDepartments;
        if (selectedTenant || selectedLocation) {
          filteredDepartments = filteredDepartments?.filter(department =>
            decryptPhishingData?.departments?.includes(department.label)
          );
        }

        let filteredLocations = contextLocations;
        if (selectedTenant || selectedDepartment) {
          filteredLocations = filteredLocations?.filter(location =>
            decryptPhishingData?.locations?.includes(location.label)
          );
        }

        contextTenants?.length && setTenants(filteredTenants);
        contextDepartments?.length && setDepartments(filteredDepartments);
        contextLocations?.length && setLocations(filteredLocations);

        return decryptPhishingData;
      }
    } catch (error) {
      console.error('[Risk] ERROR:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedTenant, selectedDepartment, selectedLocation, minMailingDate, maxMailingDate, dateRangeParam, selectedGroup]);

  const fetchPhishingGraphData = useCallback(async () => {
    try {
      if (token) {
        const data = encryptData({
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate,
          maxMailingDate,
          groupId: selectedGroup ? selectedGroup.value : null,
        });
        const link = `?company_id=${companyId ?? ''}&tenant_id=${tenantId ?? ''}&department=${departmentParam ?? ''}` +
          `&location=${locationParam ?? ''}&start_date=${startDateParam ?? ''}&end_date=${endDateParam ?? ''}`;
        const responseData = await requestWithSecondaryLoading('/back_office/api/analytics/phishing_graphs' + link, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptGraphData = decryptData(responseData);

        if (!Object.keys(decryptGraphData).length) {
          setNoData(true);

          return;
        }

        setGraphData(decryptGraphData);

        return decryptGraphData;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedTenant, minMailingDate, maxMailingDate, selectedGroup]);

  useEffect(() => {
    if (departmentParam) {
      setSelectedDepartment({ value: departmentParam, label: departmentParam });
    }
    if (locationParam) {
      setSelectedLocation({ value: locationParam, label: locationParam });
    }
    if (groupIdParam) {
      const groupName = groupNameParam || groupIdParam;
      setSelectedGroup({ value: groupIdParam, label: groupName });
    }
    if (tenantId) {
      const tenantName = contextTenants.find(tenant => tenant.id === tenantId)?.label;
      setSelectedTenant({ id: tenantId, value: tenantId, label: tenantName });
    }
    if (startDateParam) {
      setMinMailingDate({ value: startDateParam, label: startDateParam });
    }
    if (endDateParam) {
      setMaxMailingDate({ value: endDateParam, label: endDateParam });
    }
    if (dateRangeParam) {
      setSelectedDateRange(dateRangeOptionsList.find(option => option.value === +dateRangeParam));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchPhishingData();
  }, [fetchPhishingData]);

  useEffect(() => {
    fetchPhishingGraphData();
  }, [fetchPhishingGraphData]);

  const graphContainerRef = useRef(null);
  const trendGraphContainerRef = useRef(null);
  const tableContainerRef = useRef(null);

  const adjustTableHeight = useCallback(() => {
    if (
      tableContainerRef.current && trendGraphContainerRef.current
    ) {
      const trendGraphHeight = trendGraphContainerRef.current.clientHeight;
      tableContainerRef.current.style.maxHeight = `${trendGraphHeight}px`;
    }
  }, []);

  const clearGroup = () => {
    setSelectedGroup(null);
    setNoData(false);
  }

  const handleTenantChange = (data) => {
    clearGroup();
    setSelectedTenant(data);
  }

  const handleGroupChange = (data) => {
    clearGroup();
    setSelectedGroup(data);
  }

  useEffect(() => {
    adjustTableHeight();
    window.addEventListener('resize', adjustTableHeight);

    return () => {
      window.removeEventListener('resize', adjustTableHeight);
    };
  }, [adjustTableHeight]);

  const exportPhishingUsersgCsv = async () => {
    try {
      if (token) {
        setDownloadLoading(true);

        const data = encryptData({
          department: selectedDepartment ? selectedDepartment.label : null,
          location: selectedLocation ? selectedLocation.label : null,
          groupId: selectedGroup ? selectedGroup.value : null,
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate,
          maxMailingDate
        });
        const response = await fetch('/back_office/api/analytics/phishing_users_csv', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data })
        });

        if (!response.ok) {
          setDownloadLoading(false);
          // eslint-disable-next-line no-throw-literal
          throw { error: 'Failed to export CSV file!' };
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;

        a.download = `phishing_users_${minMailingDate}_${maxMailingDate}.csv`;

        a.textContent = 'Download CSV';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setDownloadLoading(false);
        showToastMessage(null, 'CSV file with Risk Rating downloaded successfully');
      }
    } catch (error) {
      setDownloadLoading(false);
      showToastMessage(error?.error, null, error?.warning);
    }
  };

  return (
    <>
      <UserAnalyticsFilterBar
        loading={loading}
        tenants={tenants}
        departments={departments}
        locations={locations}
        minMailingDate={minMailingDate}
        setMinMailingDate={setMinMailingDate}
        selectedTenant={selectedTenant}
        setSelectedTenant={handleTenantChange}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        dateRangeOptionsList={dateRangeOptionsList}
        filteredMinMailingDate={filteredMinMailingDate}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        setSelectedGroup={handleGroupChange}
        selectedGroup={selectedGroup}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        setDateRangeOptionsList={setDateRangeOptionsList}
        maxMailingDate={maxMailingDate}
        setMaxMailingDate={setMaxMailingDate}
        generateReportButtonVisible={!noData}
        generateReportCallback={generateReport}
        generateReportButtonDisabled={disabled}
        groupIdParam={groupIdParam}
        setNoData={setNoData}
      />
      {noData
        ? <div className="no-groups-message">
          No data available.
          <span className="group-link">
            <button onClick={clearGroup}>Clear filter</button>
          </span>
        </div>
        : <>
          <PhishingMetrics
            phishingData={phishingData}
            loading={loading}
          />

          <div className='trend-graphs-container trend-graphs-container-phishing'>
            <div className="trend-graphs-container-item" ref={graphContainerRef}>
              <div className="trend-graphs-container-item-graph" ref={trendGraphContainerRef}>
                <TrendGraph
                  title='Phish Click Rate Trend Graph'
                  loading={loading}
                  minMailingDate={minMailingDate}
                  maxMailingDate={maxMailingDate}
                  graphData={phishingData?.phishClickTrendGraphData || {}}
                  currValue={phishingData?.phishClickRate || '0'}
                  diffValue={phishingData?.diffPhishClickRate || 0}
                  tooltipName={'Phish Click Rate'}
                  percentage={true}
                  plusIsPositive={false}
                  stepSizeY={10}
                />
              </div>
            </div>

            <div className="trend-graphs-container-item-table" ref={tableContainerRef}>
              <ProneTable
                tablesData={tableData}
                loading={loading}
                callback={exportPhishingUsersgCsv}
                downloadLoading={downloadLoading}
                height={graphContainerRef?.current?.clientHeight ? graphContainerRef?.current?.clientHeight - 120 : 0}
              />
            </div>
          </div>
          <div className={cn('trend-graphs-container', {
            'trend-graphs-container--loading': loading
          })}>
            <TrendGraphBar
              highlited={true}
              title='Department Phish Click Rate Trend Graph'
              loading={secondaryLoading}
              minMailingDate={minMailingDate}
              maxMailingDate={maxMailingDate}
              graphData={graphData?.departmentPhishClickGraphData || {}}
              tooltipName={'Phish Click Rate'}
              percentage={true}
              percentageTicks={true}
              highlitedText={selectedDepartment?.label}
            />
            <TrendGraphBar
              title='Location Phish Click Rate Trend Graph'
              loading={secondaryLoading}
              minMailingDate={minMailingDate}
              maxMailingDate={maxMailingDate}
              graphData={graphData?.locationPhishClickGraphData || {}}
              tooltipName={'Phish Click Rate'}
              percentage={true}
              percentageTicks={true}
              highlitedText={selectedLocation?.label}
            />
          </div>
          <div className='trend-graphs-container trend-graphs-container-phishing'>
            <div className="trend-graphs-container-item">
              <div className="trend-graphs-container-item-graph">
                <TrendGraphBar
                  title='Group Phish Click Rate Trend Graph'
                  loading={secondaryLoading}
                  minMailingDate={minMailingDate}
                  maxMailingDate={maxMailingDate}
                  graphData={graphData?.groupPhishClickGraphData || {}}
                  tooltipName={'Phish Click Rate'}
                  percentage={true}
                  percentageTicks={true}
                  highlitedText={selectedGroup?.label}
                />
              </div>
            </div>
          </div>
        </>}
    </>
  );
}
