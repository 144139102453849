import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';
import cn from 'classnames';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { ChartPieMailingHistory } from './ChartPieMailingHistory';
import { MailingAndActionsFilterBar } from './MailingAndActionsFilterBar';
import { dateRangeOptions, lastThirtyDaysChunk } from '../../config/config';
import { Loader } from '../Loader/Loader';
import { MailingAndActionsList } from './MailingAndActionsList';
import { AnalyticsGeneralContext } from '../../context/AnalyticsGeneralContext';
import './MailingAndActionsMain.css';

export const MailingAndActionsMainChartList = () => {
  const { secondaryLoading, requestWithSecondaryLoading } = useHttp();
  const { token } = useAuth();
  const { encryptData, decryptData } = useCrypto();
  const {
    startDateParam, endDateParam, tenantId, userId, removeQueryParams,
    dateRangeOptionsList, setDateRangeOptionsList, params
  } = useOutletContext();
  const { generalLoading } = useContext(AnalyticsGeneralContext);

  const [mailingChartData, setMailingChartData] = useState({ labels: [], datasets: [] });
  const [actionsChartData, setActionsChartData] = useState({ labels: [], datasets: [] });
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [minDate, setMinDate] = useState(moment('2022-01-01').format('YYYY-MM-DD'));
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [mailingSumMetric, setMailingSumMetric] = useState({
    'allEmails': 0,
    'introductionEmails': 0,
    'phishingEmails': 0,
    'trainingEmails': 0,
    'congratsWarningEmails': 0,
    'administrativeEmails': 0,
    'monthlyUpdateEmails': 0
  });
  const [actionsSumMetric, setActionsSumMetric] = useState({
    'phishingOpens': 0,
    'phishingClicks': 0,
    'trainingOpens': 0,
    'trainingClicks': 0
  });
  const [selectedDateRange, setSelectedDateRange] = useState(dateRangeOptions[0]);
  const [maxDate, setMaxDate] = useState(moment().format('YYYY-MM-DD'));
  const [noData, setNoData] = useState(false);

  const fetchData = useCallback(async (
    startDate, endDate, selectedTenant, selectedUser, selectedDepartment, selectedLocation, selectedGroup
  ) => {
    try {
      if (token) {
        setNoData(false);
        const data = encryptData({
          startDate,
          endDate,
          tenantId: selectedTenant ? selectedTenant.id : null,
          selectedUser,
          department: selectedDepartment ? selectedDepartment?.value : null,
          location: selectedLocation ? selectedLocation?.value : null,
          groupId: selectedGroup ? selectedGroup.value : null
        });
        const fetched = await requestWithSecondaryLoading(`/back_office/api/analytics/mailing_history_chart`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })

        const decryptHistory = decryptData(fetched);

        if (!Object.keys(decryptHistory).length) {
          setNoData(true)
        }
        setMailingChartData(decryptHistory.mailingChartData);
        setMailingSumMetric(decryptHistory.mailingSumMetric);
        setActionsChartData(decryptHistory.actionsChartData);
        setActionsSumMetric(decryptHistory.actionsSumMetric);

        return decryptHistory
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedUser, selectedTenant, selectedDepartment, selectedLocation, selectedGroup]);

  useEffect(() => {
    if (startDate && endDate)
      fetchData(startDate, endDate, selectedTenant, selectedUser, selectedDepartment, selectedLocation, selectedGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, fetchData]);

  return (
    <div className={cn({ 'history-chart-loading': secondaryLoading || generalLoading })}>
      <MailingAndActionsFilterBar
        title={'Mailing & Actions chart'}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        selectedGroup={selectedGroup}
        setSelectedGroup={setSelectedGroup}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        minDate={minDate}
        setMinDate={setMinDate}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        maxDate={maxDate}
        setMaxDate={setMaxDate}
        startDateParam={startDateParam}
        endDateParam={endDateParam}
        tenantId={tenantId}
        userId={userId}
        removeQueryParams={removeQueryParams}
        dateRangeOptionsList={dateRangeOptionsList}
        setDateRangeOptionsList={setDateRangeOptionsList}
      />

      {noData && <div className="no-data-text-container">
        No data available.
      </div>}
      
      {startDate && endDate 
        ? <div className='history-card-chart-container'>
          <div className='history-card-chart metric-container-mailing'>
            <h3>Emails</h3>
            <div className='sum-metric'>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  All
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{mailingSumMetric?.allEmails}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Introduction
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{mailingSumMetric?.introductionEmails}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Phishing
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{mailingSumMetric?.phishingEmails}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Training
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{mailingSumMetric?.trainingEmails}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Mandatory training
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{mailingSumMetric?.mandatoryTrainingEmails}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Congrats/warning
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{mailingSumMetric?.congratsWarningEmails}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Administrative
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{mailingSumMetric?.administrativeEmails}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Monthly update
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{mailingSumMetric?.monthlyUpdateEmails}</div>
              </div>
            </div>

            <ChartPieMailingHistory
              chartData={mailingChartData}
              loading={secondaryLoading}
              type={'emails'}
              startDate={startDate}
              endDate={endDate}
              tenantId={selectedTenant?.value}
              userId={selectedUser?.value}
              selectedDateRange={selectedDateRange}
              department={selectedDepartment?.value}
              location={selectedLocation?.value}
              groupId={selectedGroup?.value}
            />
          </div>

          <div className='actions-card-chart metric-container-mailing'>
            <h3>Actions</h3>
            <div className='sum-metric sum-metric-actions'>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Phishing Opens
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{actionsSumMetric?.phishingOpens}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Phishing Clicks
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{actionsSumMetric?.phishingClicks}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Video Views
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{actionsSumMetric?.trainingViews}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Training Opens
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{actionsSumMetric?.trainingOpens}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Training Clicks
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{actionsSumMetric?.trainingClicks}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Mandatory Training Opens
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{actionsSumMetric?.mandatoryTrainingOpens}</div>
              </div>
              <div className='sum-metric-item'>
                <div className='sum-metric-label'>
                  Mandatory Training Clicks
                </div>
                <div className={cn('sum-metric-value', { 'sum-metric-value-download': secondaryLoading })}>{actionsSumMetric?.mandatoryTrainingClicks}</div>
              </div>
            </div>

            <ChartPieMailingHistory
              chartData={actionsChartData}
              loading={secondaryLoading}
              type={'actions'}
              startDate={startDate}
              endDate={endDate}
              tenantId={selectedTenant?.value}
              userId={selectedUser?.value}
              selectedDateRange={selectedDateRange}
              department={selectedDepartment?.value}
              location={selectedLocation?.value}
              groupId={selectedGroup?.value}
            />
          </div>
        </div>
        : <Loader />
      }

       <MailingAndActionsList
        selectedTenant={selectedTenant}
        selectedUser={selectedUser}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        params={params}
        department={selectedDepartment?.value}
        location={selectedLocation?.value}
        groupId={selectedGroup?.value}
      />
    </div>
  )
};
