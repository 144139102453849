import './EducationalAndPhishEmails.css';
import React, { useCallback, useEffect, useState, useContext, useId } from 'react';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import arrowback from '../../images/icons/Arrow Up 2.svg';
import { AuthContext } from '../../context/AuthContext';
import Modal from 'react-modal';
import Select, { components } from 'react-select';
import colourStyles from '../../styles/colour-style';
import { customModalStyles } from '../../config/config';


export const PhishingEmails = () => {
  const { token } = useAuth();
  const { loading, request } = useHttp();
  const { decryptData, encryptData } = useCrypto();
  const { showToastMessage } = useContext(AuthContext);

  const [allTemplates, setAllTemplates] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [exclusionTemplates, setExclusionTemplates] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [requestContent, setRequestContent] = useState('');
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState(null);

  const textAreaId = useId();
  const auth = useContext(AuthContext);

  const fetchAllTemplates = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/all_phish_templates', 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptTemplates = decryptData(fetched);
        setAllTemplates(decryptTemplates)
        setFilteredTemplates(decryptTemplates)
        const templatesUniqueSegments = [...new Set(decryptTemplates.map(e => e.segmentId))].map(id => {return { name: decryptTemplates.find(e => e.segmentId === id)?.segment.name, id }})
        setSegments(templatesUniqueSegments)

        await fetchExclusionTemplates()

        return decryptTemplates;
      }

    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const fetchExclusionTemplates = async () => {
    try {
      if (token) {
        const fetched = await request('/back_office/api/exclusions/templates', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptTemplates = decryptData(fetched);
        setExclusionTemplates(decryptTemplates)

        return decryptTemplates;
      }

    } catch (error) { }

  };

  const handleSegmentChange = (data) => {
    setSelectedSegment(data)
    searchTemplate('', data?.value)
  }

  const segmentsVariations = () => {
    const variations = segments?.map((e) => {
      return { value: e.id, label: e.name, id: e.id };
    });

    return variations
  };

  const segmentsOptions = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`langTypes-label-${value.toString().split(' ').join('-')}`}>{label}</div>
      </components.Option>
    );
  };

  const removeFromExclusions = async () => {
    try {
      const data = encryptData(selectedRow);
      const response = await request('/back_office/api/exclusions/templates', 'DELETE', { data }, {
        Authorization: `Bearer ${token}`
      })
      auth.showToastMessage(response.error, response.message);
      fetchExclusionTemplates()
    } catch (error) { }
  }

  const addToExclusions = async (e) => {
    try {
      const selectedOption = { value: selectedRow }

      const data = encryptData([selectedOption]);
      const response = await request('/back_office/api/exclusions/templates', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })
      auth.showToastMessage(response.error, response.message);
      fetchExclusionTemplates()
    } catch (error) { }
  }

  const searchTemplate = (key, selectedSegmentId=null) => {
    if (!key) {
      setFilteredTemplates(allTemplates.filter(e => selectedSegmentId ? e.segmentId === selectedSegmentId : true ));
    } else {
      const keyWord = key.toLowerCase();

      const filteredTemplates = allTemplates.filter(e => {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = e.htmlMessage;
        const text = tempElement.textContent || tempElement.innerText;
        return e.subject.toLowerCase().includes(keyWord) || text.toLowerCase().includes(keyWord) || e.name.toLowerCase().includes(keyWord)
      })
      setFilteredTemplates(filteredTemplates.filter(e => selectedSegment? e.segmentId === selectedSegment.value : true ));
  
      if (!filteredTemplates.map(e => e.id).includes(selectedRow) && filteredTemplates.length) {
        setSelectedRow(filteredTemplates[0].id)
      } else if (filteredTemplates.length === 0) {
        setSelectedRow(-1)
      }
    }
  }

  const createTicket = async (description, file) => {
    try {
      if (token) {
        setRequestLoading(true);
        const data = encryptData({ description, priority: 'Normal' });
        const res = await request(`/back_office/api/support/new`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })
        
        // if (file && res.data) {
        //   const decryptedData = decryptData(res.data)
        //   const { newIssueId } = decryptedData;
          
        //   const formData = new FormData();
        //   formData.append('file', file);
        //   formData.append('issueId', newIssueId);

        //   const url = `/back_office/api/support/new/attachment`
        //   const response = await axios.post(url, formData, {
        //     headers: {
        //       "Content-Type": "multipart/form-data",
        //     },
        //   }).catch(err => console.log(err));
        //   showToastMessage(response.error);
        // }
        showToastMessage(res.error, res.message, res.warning);
        setRequestLoading(false);
        
        if (res.message) {
          setRequestContent('');
          setSelectedFile(null);
          setFeedbackModal(false)
        }

        return res;
      }
    } catch (error) { setRequestLoading(false) }
  }

  useEffect(() => {
    fetchAllTemplates();
  }, [fetchAllTemplates]);

  const optionList = filteredTemplates.map(template => (
    { value: template.id, label: template.subject, caption: template.subject, html: template.htmlMessage }
  ))

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {!loading &&
        <>
          <div className="main-details main-details-edu">
            <div className='education-card-phish'>
              <div className='education-card-container'>
                <h2 className='templates-title'>Phishing email templates</h2>
                <div className="email-segment-select">
                  <Select
                    id="select"
                    options={segmentsVariations()}
                    components={{ segmentsOptions }}
                    placeholder="Select segment"
                    value={selectedSegment}
                    onChange={handleSegmentChange}
                    isSearchable={true}
                    styles={colourStyles}
                    isClearable
                  />
                </div>
                <input
                  id="users"
                  name="users"
                  placeholder="Search template by name, subject or content"
                  className="template-input-edit"
                  onChange={(e) => searchTemplate(e.target.value)}
                />
              </div>
              <div className='education-card-phish-tables'>
                {!!optionList.length && <div className='scroll-container equal-container-size'>
                  <table className="edu-table">
                    <tbody className="table-body" >
                      {optionList.map((u) => (
                        <tr
                          key={u.value}
                          onClick={() => setSelectedRow(u.value)}
                          className={"edu-clickable-row edu-tr ".concat(selectedRow === u.value ? "edu-selected" : "")}
                        >
                          <td>{u?.label}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>}
                {!!allTemplates.find(t => t.id === selectedRow)
                  ? <div className='preview-container equal-container-size'>
                    <div className='templates-button-container'>
                      <div className='templates-arrows-container'>
                        <button
                          className='arrow-button'
                          onClick={() => setSelectedRow(filteredTemplates[filteredTemplates.map(e => e.id).indexOf(selectedRow) - 1]?.id)}
                          disabled={filteredTemplates.map(e => e.id).indexOf(selectedRow) === 0}
                        >
                          <img src={arrowback} style={{ transform: 'rotate(-90deg)', width: '36px' }} alt='back'></img>
                        </button>
                        <button className='arrow-button'
                          onClick={() => setSelectedRow(filteredTemplates[filteredTemplates.map(e => e.id).indexOf(selectedRow) + 1]?.id)}
                          disabled={filteredTemplates.map(e => e.id).indexOf(selectedRow) === filteredTemplates.length - 1}
                        >
                          <img src={arrowback} style={{ transform: 'rotate(90deg)', width: '36px' }} alt='next'></img>
                        </button>
                      </div>
                      <div className='edu-button-container'>
                      <p className='id-container'>ID: {allTemplates.find(t => t.id === selectedRow)?.id}</p>

                        {!exclusionTemplates?.find(e => e.id === selectedRow)
                          ? <button
                            className='group-button-secondary'
                            disabled={!selectedRow}
                            onClick={addToExclusions}
                          >
                            Exclude template
                          </button>
                          : <button
                            className='group-button-secondary'
                            disabled={!selectedRow}
                            onClick={removeFromExclusions}
                          >
                            Include template
                          </button>}
                        <button
                          className='group-button group-button-simple'
                          // disabled={selectedRows.length}
                          onClick={() => setFeedbackModal(true)}
                        >
                          Give a feedback
                        </button>
                      </div>
                    </div>

                    <div className='edu-template-container'>
                      <table className="edu-table">
                        <tbody className="table-body">
                          <tr>
                            <td>
                              <div className='edu-html' dangerouslySetInnerHTML={{
                                __html: `${allTemplates.find(t => t.id === selectedRow)?.htmlMessage}`
                              }}>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  : <p className="edu-description edu-template-container">
                    {!optionList.length ? 'No templates were found' : 'No template is selected'}
                  </p>
                }
              </div>
            </div>
          </div>
        </>
      }

      <Modal
        isOpen={feedbackModal}
        onRequestClose={() => {
          setFeedbackModal(false)
          setRequestContent('')
        }}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <form className="form edu-modal">
          <div className="title edu-modal-title">
            <span className="card-title edu-card-title">Leave your feedback</span> <br></br>
          </div>
          <div className="edu-card-content">
            <div className='sync-select edu-select'>
              <textarea
                id={textAreaId}
                name='requestContent'
                placeholder='Please type your notes here...'
                className="validate input-edit edu-texarea"
              // defaultValue={getInnertext(companyMetadata['1609'])}
              // onChange={(e) => handleLinkChange('1609', e.target.value)}
              />
            </div>
            {/* <div>
              <button className='attach-button'
              // onClick={handleUploadClick}
              >
                {selectedFile ? `${selectedFile.name}` : 'Attach a file'}
              </button>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .docx, .csv, .xls, .xlsx"
                // ref={inputRef}
                // onChange={handleFileChange}
                style={{ display: 'none' }}
              />
              {selectedFile && <button onClick={() => setSelectedFile(null)} type='button' className='attach-button-cross'></button>}
            </div> */}
          </div>
          <div className='edu-modal-buttons-container'>
          <button
              className='group-button-secondary'
              onClick={() => {
                setFeedbackModal(false)
                setRequestContent('')
              }}
            >
              Cancel
            </button>

            <button
              className='group-button group-button-simple'
              disabled={!requestContent || requestLoading}
              onClick={() => createTicket(`Template ID: ${selectedRow} `+ requestContent, selectedFile)}
              title='Not available yet'
            >
              Submit
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}
