import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { AuthContext } from '../../context/AuthContext';
import { useCrypto } from '../../hooks/crypto.hook';
import Select, { components } from 'react-select';
import edit from '../.././images/icons/Pen.svg';
import arrowdown from '../.././images/icons/caret-down.svg';
import cn from 'classnames';
import colourStyles from '../../styles/colour-style';
import './SyncGroups.css';
import check from '../../images/icons/Check Green.svg';
import cancel from '../../images/icons/Cross Red.svg';
import { languageOptions, groupsOnPage } from '../../config/config';
import { NavLink } from 'react-router-dom';
import { useSortableData } from '../../hooks/sort.hook';
import settings from '../.././images/icons/gear.svg';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import { Loader } from '../Loader/Loader';


export const SyncGroups = ({ syncType }) => {
  const { request, loading } = useHttp();
  const { showToastMessage, allGroups, systemGroups } = useContext(AuthContext);
  const { decryptData, encryptData } = useCrypto();
  const { token } = useAuth();

  const [selectedRows, setSelectedRows] = useState([]);
  const [groupsShown, setGroupsShown] = useState(false);
  const [language, setLanguage] = useState();
  const [editLangModeForId, setEditLangModeForId] = useState(false);
  const [selectedGroupsShown, setSelectedGroupsShown] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedTenant, setSelectedTenant] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: 'all',
    label: 'All',
    id: 0,
  });
  const [selectedSystemGroup, setSelectedSystemGroup] = useState({
    value: 'all',
    label: 'All',
    id: 0,
  });
  const [searchValue, setSearchValue] = useState('');

  const sortGroups = useCallback(() => {
    setFilteredGroups(prevGroups => {
      const selectedIds = new Set(selectedRows.map(e => e.id));
  
      return [...prevGroups].sort((a, b) => {
        if (selectedGroupsShown) {
          const aSelected = selectedIds.has(a.id);
          const bSelected = selectedIds.has(b.id);

          if (aSelected !== bSelected) return aSelected ? -1 : 1;
        }
  
        return a.name.localeCompare(b.name);
      });
    });
  }, [selectedGroupsShown, selectedRows]);
  
  const fetchGroups = useCallback(async () => {
    if (token && selectedTenant?.value) {
      const fetched = await request(
        `/back_office/api/groups/sync_group_list?tenantId=${selectedTenant?.value}`, 'GET', null, {
        Authorization: `Bearer ${token}`,
      })

      const groups = decryptData(fetched)

      const remoteGroups = groups.map(e => {
        const systemGroups = e.groupMappings.map((e) => {
          return {
            id: e.groupId,
            name: allGroups.find((group) => group.id === e.groupId)?.label,
          }
        })

        return {
          id: e.id,
          name: e.name,
          tenant: e.tenant,
          language: e.language || e.tenant.language || 'English',
          userCount: e.userCount,
          systemGroups,
        }
      })

      setGroups(remoteGroups);
      setFilteredGroups(remoteGroups);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request, selectedTenant]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  useEffect(() => {
    sortGroups();
  }, [sortGroups]);

  const fetchAllTenants = useCallback(async () => {
    try {
      if (token) {
        const fetched = await request(
          '/back_office/api/user/get_all_tenants',
          'GET',
          null,
          {
            Authorization: `Bearer ${token}`,
          }
        );
        const decryptTenants = decryptData(fetched);
        const filteredTenants = decryptTenants.filter(t => t.type === syncType)
        setTenants(filteredTenants);
        if (filteredTenants?.length) {
          const { id, name, type } = filteredTenants[0];
          setSelectedTenant({ ...filteredTenants[0], value: id, label: name, id: 0, syncType: type });
        }

        return filteredTenants;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  const changeLanguageMapping = async ({ groups, language }) => {
    const selectedData = {
      groups,
      language,
    };

    if (token) {
      const data = encryptData(selectedData);
      const response = await request(
        `/back_office/api/groups/change/language_map`,
        'POST',
        { data },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      fetchAllTenants();
      setSelectedRows([]);
      showToastMessage(response.error, response.message);
    }
  };

  const languageList = languageOptions.map((v, i) => {
    return { value: v?.toLowerCase(), label: v, id: i, key: i, type: 'language' };
  });

  const systemGroupsOptions = systemGroups.map((e) => {
    return {
      value: e.id,
      label: e.label,
      key: e.id,
    }
  })
  const languageOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`actionTypes-label-${value.toString().split(' ').join('-')}`}>{label}</div>
      </components.Option>
    );
  };

  const clearFilters = () => {
    setSelectedLanguage({ value: 'all', label: 'All', id: 0 });
    setSelectedSystemGroup({ value: 'all', label: 'All', id: 0 });
    setSearchValue('');
    setFilteredGroups(groups);
  }

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setSelectedRows([]);
  };

  const handleLanguageChange = (data) => {
    setSelectedLanguage(data);
  };

  const handleGroupChange = (data) => {
    setSelectedSystemGroup(data);
  };

  const searchGroups = useCallback(() => {
    const result = groups
    .filter(
      (group) =>
        (selectedSystemGroup?.value !== 'all'
          ? group.systemGroups.find(e => e.name === selectedSystemGroup?.label)
          : true) && (
          selectedLanguage.value !== 'all'
            ? group?.language === selectedLanguage?.label
            : true) && (
          searchValue !== ''
            ? group.name.toLowerCase().includes(searchValue.toLowerCase())
            : true
        )
    )
  setFilteredGroups(result);
  setSelectedRows([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, groups, selectedLanguage.value, selectedSystemGroup.value]);

  useEffect(() => {
    searchGroups();
  }, [searchGroups]);

  const { items, requestSort, sortConfig } = useSortableData(filteredGroups);
  const tenantList = tenants.map((tenant, i) => {
    return {
      ...tenant,
      value: tenant.id,
      label: tenant.name.trim(),
      id: i,
      type: 'tenant',
      syncType: tenant.type,
    };
  });

  const getSystemGroupsList = (groups) => {
    if (groups.length) {
    return groups.map(({ name }, i) => (
        <span className="group-link" key={i}>
          <NavLink to={`/back_office/groups/${name.split(' ').join('_')}`}>
            {name}
          </NavLink>
        </span>))
    }
    return 'No mapped groups'
  }

  const Option = (props) => {
    const { label, value, caption, type } = props.data;

    return (
      <components.Option {...props}>
        <div
          className={cn(
            `syncTypes-label-${value.toString().split(' ').join('-')}`,
            `actions-label-${value.toString().split(' ').join('-')}`,
            `sync-groups-select-icon-${type}`,
            'sync-groups-select-icon'
          )}
        >
          {label}
        </div>
        <div className="caption">{caption}</div>
      </components.Option>
    );
  };

  const handleMapChange = async (groups, language) => {
    await changeLanguageMapping({
      groups,
      language,
    });

    setEditLangModeForId(false);
    setLanguage('');
  };

  const removeGroup = (groupId) => {
    setSelectedRows((prevState) => prevState.filter((e) => e.id !== groupId));
  };

  const addGroup = (groupId, groupName, tenantId) => {
    const newGroup = {
      id: groupId,
      name: groupName,
      tenantId,
    };
    setSelectedRows((prevState) => [
      ...prevState, newGroup]);
  };

  useEffect(() => {
    fetchAllTenants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAllTenants]);

  return (
    <>
      {!!groups?.length && (
        <div className="groups-card">
          <div className="groups-table-title">
            <div className="groups-table-title">
              <h3>Groups</h3>
              <div className="log-groups-count">{filteredGroups?.length}</div>
            </div>
            <button
              className="sync-groups-arrow-button"
              onClick={() => setGroupsShown((prev) => !prev)}
            >
              {!groupsShown ? (
                <img src={arrowdown} alt="arrow down" />
              ) : (
                <img
                  style={{ transform: 'rotate(180deg)' }}
                  src={arrowdown}
                  alt="arrow up"
                />
              )}
            </button>
          </div>
          {groupsShown && (
            <div>
              <div className="sync-groups-filters">
                <div className="sync-group-select userslist-select-tenant">
                  <label className="userslist-select-label">
                    Tenant
                    <Select
                      id="select"
                      className="sync-groups-select"
                      options={tenantList}
                      components={{ Option }}
                      placeholder="Select tenant"
                      value={selectedTenant}
                      onChange={handleTenantChange}
                      isSearchable={true}
                      styles={colourStyles}
                    />
                  </label>
                </div>
                <div className=" sync-group-select userslist-select-language">
                  <label className="userslist-select-label">
                    Language
                    <Select
                      id="select"
                      className="sync-groups-select"
                      options={[
                        {
                          value: 'all',
                          label: 'All',
                          id: 0,
                        },
                        ...languageList,
                      ]}
                      components={{ Option }}
                      placeholder="Select language"
                      value={selectedLanguage}
                      onChange={handleLanguageChange}
                      isSearchable={true}
                      styles={colourStyles}
                    />
                  </label>
                </div>
                <div className="sync-group-select userslist-select-groups">
                  <label className="userslist-select-label">
                    System Group
                    <Select
                      id="select"
                      className="sync-groups-select"
                      options={[
                        {
                          value: 'all',
                          label: 'All',
                          id: 0,
                        },
                        ...systemGroupsOptions,
                      ]}
                      components={{ Option }}
                      placeholder="Select group"
                      value={selectedSystemGroup}
                      onChange={handleGroupChange}
                      isSearchable={true}
                      styles={colourStyles}
                    />
                  </label>
                </div>
              </div>
              <div className="input-search-groups">
                <label className="userslist-select-label">
                  Search Group
                </label>
                <input
                  placeholder="Type to search"
                  value={searchValue}
                  type='text'
                  onChange={async (e) => {
                    setSearchValue(e.target.value)
                    setPage(1);
                  }
                  }
                />
                <i
                  role='button'
                  onClick={() => setSearchValue('')}
                  className={cn({ 'tenants-clear-disabled': !searchValue.length })}
                />
              </div>

              {filteredGroups.length && !loading ? (
                <div>
                  <div
                    className={cn('all-lang-container', {
                      'sync-groups-container-empty':
                        !selectedRows?.length || !selectedGroupsShown,
                    })}
                  >
                    <div className="all-languages-selected-bar">
                      <button
                        className={
                          selectedGroupsShown
                            ? 'all-groups-button-active'
                            : 'all-groups-button'
                        }
                        onClick={() => setSelectedGroupsShown((prev) => !prev)}
                      >
                        {selectedRows.length < 2
                          ? `${selectedRows.length} group selected`
                          : `${selectedRows.length} groups selected`}
                      </button>
                      {!!selectedRows.length && (
                        <button
                          className="all-groups-secondary-button"
                          onClick={() => setSelectedRows([])}
                        >
                          Clear all
                        </button>
                      )}
                    </div>

                    {!!selectedRows.length ? (
                      <div className="lang-select-container">
                        <Select
                          id="select"
                          className="lang-select"
                          options={languageList}
                          components={{ languageOption }}
                          placeholder="Select language"
                          value={language}
                          onChange={setLanguage}
                          isSearchable={true}
                          styles={colourStyles}
                        />
                        <button
                          className="submit-button"
                          onClick={() => {
                            handleMapChange(selectedRows, language.label);
                          }}
                          disabled={!selectedRows.length || !language}
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    ) : (
                      <span className="group-link">
                        <NavLink to={`/back_office/groups/mapping`}>
                          Go to group mapping
                        </NavLink>
                      </span>
                    )}
                  </div>

                  <table className="logs-table">
                    <thead className="table-th-logs">
                      <tr
                        style={{ width: '100%' }}
                        className="not-clickable-groups tr-grid-groups tr-groups"
                      >
                        <th style={{ width: '5%' }}>
                        </th>
                        <th style={{ width: '20%' }}>
                          <button
                            type="button"
                            onClick={() => requestSort('name')}
                            className={getClassNamesFor('name', sortConfig)}
                          >
                            Sync group name
                          </button>
                        </th>
                        <th style={{ width: '7%' }}>
                          <button
                            type="button"
                            onClick={() => requestSort('userCount')}
                            className={getClassNamesFor('userCount', sortConfig)}
                          >
                            Users
                          </button>
                        </th>
                        <th style={{ width: '13%' }}>
                          Tenant name
                        </th>
                        <th style={{ width: '20%' }}>
                          <button
                            type="button"
                            onClick={() => requestSort('language')}
                            className={getClassNamesFor('language', sortConfig)}
                          >
                            Language
                          </button>
                        </th>
                        <th style={{ width: '20%' }}>System group(s)</th>
                      </tr>
                    </thead>
                    <tbody className="table-body-logs">
                      {items.map((group, i) => {
                        if (i < page * groupsOnPage) {
                          return (
                            <tr
                              key={group.id}
                              className={cn(
                                'tr-grid-groups clickable-row-logs tr-groups-csv'
                              )}
                            >
                              <td>
                                <div className="user-checkbox-action">
                                  <label className="checkbox-label">
                                    <input
                                      type="checkbox"
                                      className="checkbox"
                                      checked={selectedRows?.find((e) => e.id === group.id)}
                                      onChange={() => {
                                        selectedRows?.find((e) => e.id === group.id)
                                          ? removeGroup(group.id)
                                          : addGroup(group.id, group.name, group.tenant.id);

                                        if (selectedRows.length) {
                                          setEditLangModeForId(false);
                                        }
                                      }}
                                    />
                                    <span className="checkbox-span"></span>
                                  </label>
                                </div>
                              </td>
                              <td>
                                {group.name}
                                <p className="secondary-info">{`ID: ${group.id}`}</p>
                              </td>
                              <td style={{ textAlign: 'center' }}>{group.userCount}</td>
                              <td>{group.tenant.name}</td>
                              <td>
                                <div
                                  className={
                                    editLangModeForId === group.id &&
                                      !selectedRows.length
                                      ? 'icon-button-container'
                                      : 'table-group-button-container groups-log-details'
                                  }
                                >
                                  {editLangModeForId === group.id &&
                                    !selectedRows.length ? (
                                    <Select
                                      id="select"
                                      className="lang-select lang-select-in-row"
                                      options={languageList}
                                      components={{ languageOption }}
                                      placeholder="Select language"
                                      value={language}
                                      defaultValue={group.language || 'English'}
                                      onChange={setLanguage}
                                      isSearchable={true}
                                      styles={colourStyles}
                                    />
                                  ) : (
                                    group.language ||
                                    group.tenant.language ||
                                    'English'
                                  )}

                                  {editLangModeForId === group.id &&
                                    !selectedRows.length ? (
                                    <div className="group-lang-buttons-container">
                                      <button
                                        type="button"
                                        className="groups-table-option"
                                        onClick={() => {
                                          handleMapChange(
                                            [
                                              {
                                                tenantId: group.tenant.id,
                                                id: group.id,
                                              },
                                            ],
                                            language.label,
                                          );
                                        }}
                                        disabled={!language}
                                      >
                                        <img src={check} alt="edit"></img>
                                      </button>
                                      <button
                                        type="button"
                                        className="groups-table-option"
                                        onClick={() => {
                                          setEditLangModeForId(false);
                                        }}
                                      >
                                        <img src={cancel} alt="edit"></img>
                                      </button>
                                    </div>
                                  ) : !selectedRows.length ? (
                                    <button
                                      type="button"
                                      className="groups-table-option"
                                      onClick={() => {
                                        setEditLangModeForId(group.id);
                                      }}
                                    >
                                      <img src={edit} alt="edit"></img>
                                    </button>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="icon-button-container">
                                  <div className="group-lang-buttons-container">
                                    {getSystemGroupsList(group.systemGroups)}
                                  </div>
                                  <NavLink
                                    to={'/back_office/groups/mapping'}
                                    state={{ tenantId: group.tenant.id, syncGroupId: group.id }}
                                    className="mapping-link"
                                  >
                                    <button
                                      type="button"
                                      className="groups-table-option"
                                    >
                                      <img src={settings} alt="edit"></img>
                                    </button>
                                  </NavLink>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                        return null;
                      })}
                    </tbody>
                  </table>

                  {page * groupsOnPage < filteredGroups.length && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <button
                        className="list-button"
                        onClick={() => {
                          setPage((prev) => prev + 1);
                        }}
                        type="submit"
                      >
                        Download more
                      </button>
                    </div>
                  )}
                </div>
              ) : !loading ? (
                <div className="no-groups-message">
                  No groups matching your filters
                  <span className="group-link">
                    <button onClick={clearFilters}>Clear filters</button>
                  </span>
                </div>
              )
                : (
                  <Loader />
                )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
